<template>
  <div id="screening-history" class="steper-content">
    <div class="md-layout">
      <div class="md-layout-item md-size-25 md-small-size-100">
        <h4 class="title text-center">Historical Information</h4>
        <div class="md-layout historical-information">
          <div class="md-layout-item md-size-50">
            <p>Eye Surgery:</p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>
              {{
                patientHistory.value !== null
                  ? patientHistory.value.surgery
                  : ""
              }}
            </p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>Cancer:</p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>
              {{
                patientHistory.value !== null ? patientHistory.value.cancer : ""
              }}
            </p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>Diabetes:</p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>
              {{
                patientHistory.value !== null
                  ? patientHistory.value.diabeties
                  : ""
              }}
            </p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>Personal Other:</p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>
              {{
                patientHistory.value !== null ? patientHistory.value.others : ""
              }}
            </p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>Family Other:</p>
          </div>
          <div class="md-layout-item md-size-50">
            <p>
              {{
                patientFamilyHistory.value != null
                  ? patientFamilyHistory.value.other
                  : ""
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-30 md-small-size-100" align="center">
        <h4 class="title text-center">Medical Information</h4>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <div class="checkbox-list-no-border scroll">
              <md-radio
                v-model="mHyp"
                :value="mHyp"
                :disabled="
                  !patientHistory.value.pressure ||
                  patientHistory.value.pressure == 'false'
                "
                class="sasset"
                >Pressure</md-radio
              >
              <md-radio
                v-model="mStr"
                :value="mStr"
                :disabled="
                  !patientHistory.value.stroke ||
                  patientHistory.value.stroke == 'false'
                "
                class="sasset"
                >Stroke</md-radio
              >
              <md-radio
                v-model="mLiv"
                :value="mLiv"
                :disabled="
                  !patientHistory.value.liver ||
                  patientHistory.value.liver == 'false'
                "
                class="sasset"
                >Liver Disease</md-radio
              >
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <div class="checkbox-list-no-border scroll">
              <md-radio
                v-model="mCho"
                :value="mCho"
                :disabled="
                  !patientHistory.value.cholestrol ||
                  patientHistory.value.cholestrol == 'false'
                "
                class="sasset"
                >Cholesterol</md-radio
              >
              <md-radio
                v-model="mMig"
                :value="mMig"
                :disabled="
                  !patientHistory.value.migranes ||
                  patientHistory.value.migranes == 'false'
                "
                class="sasset"
                >Migraines</md-radio
              >
              <md-radio
                v-model="mHrt"
                :value="mHrt"
                :disabled="
                  !patientHistory.value.heart ||
                  patientHistory.value.heart == 'false'
                "
                class="sasset"
                >Heart Disease</md-radio
              >
            </div>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <h4 class="title text-center">Family Information</h4>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <h4 class="title text-center">Ocular:</h4>
            <div class="checkbox-list-no-border scroll">
              <md-radio
                v-model="fGla"
                :value="fGla"
                :disabled="
                  !patientFamilyHistory.value.glaucoma ||
                  patientFamilyHistory.value.glaucoma == 'false'
                "
                class="sasset"
                >Glaucoma</md-radio
              >
              <md-radio
                v-model="fMac"
                :value="fMac"
                :disabled="
                  !patientFamilyHistory.value.macDegen ||
                  patientFamilyHistory.value.macDegen == 'false'
                "
                class="sasset"
                >Macular Degeneration</md-radio
              >
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <h4 class="title text-center">Systemic:</h4>
            <div class="checkbox-list-no-border scroll">
              <md-radio
                v-model="fCan"
                :value="fCan"
                :disabled="
                  !patientFamilyHistory.value.cancer ||
                  patientFamilyHistory.value.cancer == 'false'
                "
                class="sasset"
                >Cancer</md-radio
              >
              <md-radio
                v-model="fDia"
                :value="fDia"
                :disabled="
                  !patientFamilyHistory.value.diabetes ||
                  patientFamilyHistory.value.diabetes == 'false'
                "
                class="sasset"
                >Diabetes</md-radio
              >
              <md-radio
                v-model="fMig"
                :value="fMig"
                :disabled="
                  !patientFamilyHistory.value.migraines ||
                  patientFamilyHistory.value.migraines == 'false'
                "
                class="sasset"
                >Migraines</md-radio
              >
              <md-radio
                v-model="fMig"
                :value="fMig"
                :disabled="
                  !patientFamilyHistory.value.pressure ||
                  patientFamilyHistory.value.pressure == 'false'
                "
                class="sasset"
                >Pressure</md-radio
              >

              <md-radio
                v-model="fMig"
                :value="fMig"
                :disabled="
                  !patientFamilyHistory.value.stroke ||
                  patientFamilyHistory.value.stroke == 'false'
                "
                class="sasset"
                >Stroke</md-radio
              >
              <md-radio
                v-model="fCho"
                :value="fCho"
                :disabled="
                  !patientFamilyHistory.value.cholesterol ||
                  patientFamilyHistory.value.cholesterol == 'false'
                "
                class="sasset"
                >Cholesterol</md-radio
              >
              <md-radio
                v-model="fLiv"
                :value="fLiv"
                :disabled="
                  !patientFamilyHistory.value.liver ||
                  patientFamilyHistory.value.liver == 'false'
                "
                class="sasset"
                >Liver Disease</md-radio
              >
              <md-radio
                v-model="fHrt"
                :value="fHrt"
                :disabled="
                  !patientFamilyHistory.value.heart ||
                  patientFamilyHistory.value.heart == 'false'
                "
                class="sasset"
                >Heart Disease</md-radio
              >
              <md-radio
                v-model="fAlz"
                :value="fAlz"
                :disabled="
                  !patientFamilyHistory.value.alzheimers ||
                  patientFamilyHistory.value.alzheimers == 'false'
                "
                class="sasset"
                >Alzheimer's</md-radio
              >
            </div>
          </div>
        </div>
      </div>
      <h4 class="title text-center" style="padding-left: 30px">
        Completed Tests :
      </h4>
      <div class="cp">
        <md-radio
          v-model="ichist"
          :value="ichist"
          class="sasset"
          :disabled="
            !(
              (patientHistory.value.surgery &&
                patientHistory.value.surgery != '') ||
              (patientHistory.value.cancer &&
                patientHistory.value.cancer != '') ||
              (patientHistory.value.diabeties &&
                patientHistory.value.diabeties == 'true') ||
              patientHistory.value.pressure == 'true' ||
              patientHistory.value.stroke == 'true' ||
              patientHistory.value.liver == 'true' ||
              patientHistory.value.cholestrol == 'true' ||
              patientHistory.value.migranes == 'true' ||
              patientHistory.value.heart == 'true' ||
              (patientHistory.value.others &&
                patientHistory.value.others != '') ||
              patientFamilyHistory.value.alzheimers == 'true' ||
              patientFamilyHistory.value.cancer == 'true' ||
              patientFamilyHistory.value.cholesterol == 'true' ||
              patientFamilyHistory.value.diabetes == 'true' ||
              patientFamilyHistory.value.glaucoma == 'true' ||
              patientFamilyHistory.value.heart == 'true' ||
              patientFamilyHistory.value.liver == 'true' ||
              patientFamilyHistory.value.macDegen == 'true' ||
              patientFamilyHistory.value.migraines == 'true' ||
              patientFamilyHistory.value.pressure == 'true' ||
              patientFamilyHistory.value.stroke == 'true' ||
              (patientFamilyHistory.value.other &&
                patientFamilyHistory.value.other != '')
            )
          "
          >Hist
        </md-radio>
        <md-radio
          v-model="icvis"
          :value="icvis"
          class="sasset"
          :disabled="!isCompleted.vision"
          >Vision</md-radio
        >
        <md-radio
          v-model="iciop"
          :value="iciop"
          class="sasset"
          :disabled="!isCompleted.IOP"
          >IOP</md-radio
        >
        <md-radio
          v-model="icanterior"
          :value="icanterior"
          class="sasset"
          :disabled="!isCompleted.anteroir"
          >ANT</md-radio
        >
        <md-radio
          v-model="icext"
          :value="icext"
          class="sasset"
          :disabled="!isCompleted.externalPhoto"
          >EXT</md-radio
        >
        <md-radio
          v-model="iccor"
          :value="iccor"
          class="sasset"
          :disabled="!isCompleted.cornealTopography"
          >COR</md-radio
        >
        <md-radio
          v-model="icant"
          :value="icant"
          class="sasset"
          :disabled="!isCompleted.anteriorSegmentOCT"
          >PAC</md-radio
        >

        <md-radio
          v-model="iclens"
          :value="iclens"
          class="sasset"
          :disabled="!isCompleted.lensStatus"
        >
          OPA
        </md-radio>
        <md-radio
          v-model="icfun"
          :value="icfun"
          class="sasset"
          :disabled="!isCompleted.fundusPhoto"
          >FUN</md-radio
        >

        <md-radio
          v-model="icmac"
          :value="icmac"
          class="sasset"
          :disabled="!isCompleted.macularOCT"
          >MAC</md-radio
        >
        <md-radio
          v-model="icopt"
          :value="icopt"
          class="sasset"
          :disabled="!isCompleted.opticNerveOCT"
          >NRV</md-radio
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "patientHistory",
  inject: ["patientHistory", "patientFamilyHistory", "isCompleted"],
  data() {
    return {
      mHyp: null,
      mStr: null,
      mLiv: null,
      mCho: null,
      mMig: null,
      mHrt: null,
      fDia: null,
      fGla: null,
      fAlz: null,
      fLiv: null,
      fCan: null,
      fCho: null,
      fMig: null,
      fHrt: null,
      fMac: null,
      icext: null,
      icfun: null,
      icant: null,
      iciop: null,
      icvis: null,
      iclens: null,
      icmac: null,
      icopt: null,
      iccor: null,
      icanterior: null,
      ichist: null,
    };
  },
};
</script>
<style scoped>
.cp {
  padding-left: 80px;
  margin-top: 3px;
}
.historical-information p {
  margin-top: 7px;
  margin-bottom: 7px;
}
</style>
