<template>
  <div>
    <md-dialog :md-active.sync="previewReport.show">
      <loading :active="Dpage" :can-cancel="true"></loading>
      <div class="d-flex justify-content-between">
        <md-button class="md-primary genrpt" @click="popup"
          >Generate Report</md-button
        >
        <md-button class="md-danger-outline" @click="showpreviewClick(false)"
          >Close Preview</md-button
        >
      </div>
      <div class="scroll p-2 overflow-y-auto">
        <md-card
          id="rptpreview"
          class="md-with-hover mt-3"
          style="margin: 20px; padding: 20px"
        >
          <md-card-content>
            <file-upload-component-handler
              ref="fileUpload"
              :options="{
                feature: 'Reports',
                featureId: scrId,
                category: 'REPORT',
              }"
              v-slot="{ handleFilesSelected }"
            >
              <vue-html2pdf
                :show-layout="false"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :paginate-elements-by-height="700"
                filename="Screening_Report"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="700px"
                ref="html2Pdf"
                :html-to-pdf-options="htmlToPdfOptions"
                @beforeDownload="beforeDownload($event, handleFilesSelected)"
              >
                <section slot="pdf-content">
                  <ReportPreview></ReportPreview>
                </section>
              </vue-html2pdf>
            </file-upload-component-handler>
          </md-card-content>
        </md-card>
      </div>
    </md-dialog>
    <div>
      <md-dialog
        :md-active.sync="popcon"
        :md-fullscreen="false"
        class="alert-dialog"
      >
        <div class="alert-message danger">
          <!-- <div class="alert-header">
        <div @click="confirmation.show = false">
          <md-icon>close</md-icon>
        </div>
      </div> -->
          <div class="alert-body">
            <h1><i class="fa fa-times-circle"></i> Register this Report ?</h1>
            <p>"Notification will be sent to member to view results"</p>
          </div>
          <div class="button-container">
            <md-button @click="Cancel()">NO, CANCEL</md-button>
            <md-button class="md-danger" @click="generatepdf"
              >YES, REGISTER IT</md-button
            >
          </div>
        </div>
      </md-dialog>
    </div>
  </div>
</template>
<script>
import ReportPreview from "./preview-report.vue";
import VueHtml2pdf from "vue-html2pdf";
import FileUploadComponentHandler from "../../../handlers/common-handlers/FileUploadComponentHandler";
import moment from "moment";
import { mapState } from "vuex";
import { mapActions } from "vuex";

//import ReportGenHandler from "../../../handlers/module-handlers/reports/reportgenHandler";

export default {
  name: "DemoReport",
  components: {
    VueHtml2pdf,
    ReportPreview,
    FileUploadComponentHandler,
  },
  inject: [
    "saveScreeningEvaluation",
    "previewReport",
    "showpreviewClick",
    "handleReportgenProcess",
    "app",
    "confirmation",
    "patientinfo",
    "screeninginfo",
    "screeningVision",
    "externalphoto",
    "Corneaphoto",
    "Fundusphoto",
    "FundusODphoto",
    "FundusOSphoto",
    "lensStatus",
    "confirmation",
    "AnteriorSegment",
    "AnteriorOSphoto",
    "AnteriorODphoto",
    "findings",
    "corneaODphoto",
    "corneaOSphoto",
    "findingsMaster",
    "findings",
    "additionalFindings",
    "commonFindings",
    "selectedAdditionalFindings",
    "selectedCommonFindings",
    "getLableFindings",
    "isEvaluated",
    "selecteddiagnosis",
    "selectedrecommendation",
    "handleImageError",
    "prescriptionData",
    "MacularODphoto",
    "MacularOSphoto",
    "NerveODphoto",
    "NerveOSphoto",
    "diagnosisComments",
    "recommendationComments",
    "manageScreening",
    "handleFilesSelected",
    "handleFilesSend",
  ],
  data() {
    return {
      scrId: this.$route.params.id,
      Dpage: false,
      popcon: false,
      files: {
        list: [],
      },
    };
  },

  computed: {
    ...mapState({
      recommendedDoc: (state) => state.screeningeval.ReferralDoctors,
    }),
    htmlToPdfOptions() {
      return {
        margin: [13, 10],
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
      };
    },
  },
  methods: {
    ...mapActions("reports", ["reportpdjgeneration"]),
    ...mapActions("files", ["postFiles", "uploadFile"]),
    // async beforeDownload(
    //   { html2pdf, options, pdfContent },
    //   handleFilesSelected
    // ) {
    //   this.Dpage = true;
    //   await html2pdf()
    //     .set({
    //       ...options,
    //       pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    //     })
    //     .from(pdfContent)
    //     .toPdf()
    //     .get("pdf")
    //     .then((pdf) => {
    //       const totalPages = pdf.internal.getNumberOfPages();
    //       for (let i = 1; i <= totalPages; i++) {
    //         pdf.setPage(i);
    //         pdf.setFontSize(8);
    //         pdf.setTextColor(150);
    //         pdf.text(
    //           "What we see may save your sight!",
    //           pdf.internal.pageSize.getWidth() * 0.05,
    //           pdf.internal.pageSize.getHeight() - 2.0
    //         );
    //         pdf.text(
    //           "1-877-GlobeChek | www.GlobeChek.com",
    //           pdf.internal.pageSize.getWidth() * 0.7,
    //           pdf.internal.pageSize.getHeight() - 2.0
    //         );
    //       }
    //       fetch("data:application/pdf;base64," + btoa(pdf.output()))
    //         .then((res) => res.blob())
    //         .then((blob) => {
    //           const file = new File([blob], this.scrId + ".pdf", {
    //             lastModified: new Date().getTime(),
    //             type: "application/pdf",
    //           });
    //           handleFilesSelected([file]);
    //         });
    //     });
    //   this.Dpage = false;
    // },
    geneartionofnewpdf() {
      let pdfobj = {};
      let imgary = [
        {
          imgLink: {
            href: "https://i.postimg.cc/jd67ZpVx/imageNA.jpg",
          },
        },
      ];
      pdfobj.screeningID = this.manageScreening.screeningId;
      pdfobj.PatientName = this.patientinfo.value.name;
      pdfobj.PatientScanID =
        this.screeninginfo.value.deviceName +
        "-" +
        this.$route.params.id.substring(0, 10).toUpperCase();
      pdfobj.PatientScanDate = this.getConvertedDate();
      pdfobj.PatientDateofbirth =
        this.patientinfo.value.dob + "(" + this.patientinfo.value.age + ")";
      pdfobj.PatientEmail = this.patientinfo.value.email;
      pdfobj.PatientCellNumber = this.patientinfo.value.phone;
      pdfobj.PatientAddress1 = this.patientinfo.value.desktopaddress1;
      pdfobj.PatientAddress2 = this.patientinfo.value.desktopaddress2;
      pdfobj.PatientCity = this.patientinfo.value.city;
      pdfobj.PatientState = this.patientinfo.value.state;
      pdfobj.PatientZipCode = this.patientinfo.value.zipcode;
      pdfobj.PatientZip = this.patientinfo.value.zipcode;
      pdfobj.DistanceVAbraces =
        this.screeninginfo.value.screeningVision
          .vision_acuity_with_correctionD == "n/a" ||
        this.screeninginfo.value.screeningVision
          .vision_acuity_with_correctionD == null
          ? "sc"
          : "cc";
      pdfobj.DistanceVARighteye =
        this.screeningVision.value.va_od_dist !== null
          ? this.screeningVision.value.va_od_dist
          : "n/a";
      pdfobj.DistanceVARlefteye =
        this.screeningVision.value.va_os_dist !== null
          ? this.screeningVision.value.va_os_dist
          : "n/a";
      pdfobj.NearVADistanceVAbraces =
        this.screeninginfo.value.screeningVision
          .vision_acuity_with_correctionN == "n/a" ||
        this.screeninginfo.value.screeningVision
          .vision_acuity_with_correctionN == null
          ? "sc"
          : "cc";
      pdfobj.NearVADistanceVARighteye = this.screeningVision.value.va_od_near;
      pdfobj.NearVADistanceVALeftteye = this.screeningVision.value.va_os_near;
      pdfobj.ColorRighteye =
        this.screeningVision.value.va_od_color !== ""
          ? this.screeningVision.value.va_od_color
          : "n/a";
      pdfobj.ColorLeftteye =
        this.screeningVision.value.va_os_color !== ""
          ? this.screeningVision.value.va_os_color
          : "n/a";
      pdfobj.SphereRighteye =
        this.screeningVision.value.ref_od_sph !== ""
          ? this.screeningVision.value.ref_od_sph
          : "n/a";
      pdfobj.SphereLeftteye =
        this.screeningVision.value.ref_os_sph !== ""
          ? this.screeningVision.value.ref_os_sph
          : "n/a";
      pdfobj.AngleRighteye =
        this.screeningVision.value.ant_od_ang !== null
          ? this.screeningVision.value.ant_od_ang
          : "n/a";
      pdfobj.AngleLeftteye =
        this.screeningVision.value.ant_os_ang !== null
          ? this.screeningVision.value.ant_os_ang
          : "n/a";
      pdfobj.EyePressureRighteye =
        this.screeningVision.value.iop_od_eyepres !== null
          ? this.screeningVision.value.iop_od_eyepres
          : "n/a";
      pdfobj.EyePressureLeftteye =
        this.screeningVision.value.iop_os_eyepres !== null
          ? this.screeningVision.value.iop_os_eyepres
          : "n/a";
      pdfobj.DepthLeftteye =
        this.screeningVision.value.va_os_stereo !== ""
          ? this.screeningVision.value.va_os_stereo
          : "n/a";
      pdfobj.DepthRighteye =
        this.screeningVision.value.va_od_stereo !== ""
          ? this.screeningVision.value.va_od_stereo
          : "n/a";
      pdfobj.CylinderLefteye =
        this.screeningVision.value.ref_os_cyl !== ""
          ? this.screeningVision.value.ref_os_cyl
          : "n/a";
      pdfobj.CylinderRighteye =
        this.screeningVision.value.ref_od_cyl !== ""
          ? this.screeningVision.value.ref_od_cyl
          : "n/a";
      pdfobj.ThicknessLefteye =
        this.screeningVision.value.ant_os_thick !== ""
          ? this.screeningVision.value.ant_os_thick
          : "n/a";
      pdfobj.ThicknessRighteye =
        this.screeningVision.value.ant_od_thick !== ""
          ? this.screeningVision.value.ant_od_thick
          : "n/a";
      pdfobj.CorrectedIOPLefteye =
        this.screeningVision.value.iop_os_correct !== ""
          ? this.screeningVision.value.iop_os_correct
          : "n/a";
      pdfobj.CorrectedIOPRighteye =
        this.screeningVision.value.iop_od_correct !== ""
          ? this.screeningVision.value.iop_od_correct
          : "n/a";
      pdfobj.AxisLefteye =
        this.screeningVision.value.ref_os_axis !== ""
          ? this.screeningVision.value.ref_os_axis
          : "n/a";
      pdfobj.AxisRighteye =
        this.screeningVision.value.ref_od_axis !== ""
          ? this.screeningVision.value.ref_od_axis
          : "n/a";
      pdfobj.reccomendeddoc = this.recommendedDoc;
      pdfobj.diagnosisComments = this.diagnosisComments.value;
      pdfobj.recommendationComments = this.recommendationComments.value;
      pdfobj.ExternalPhotofindingsOD = this.getLableFindings(
        "ExternalPhoto",
        "right",
        "OD"
      );
      pdfobj.ExternalPhotofindingsOS = this.getLableFindings(
        "ExternalPhoto",
        "left",
        "OS"
      );
      pdfobj.CornealTopographyODfindings = this.getLableFindings(
        "CornealTopography",
        "right",
        "OD"
      );
      pdfobj.CornealTopographOSfindings = this.getLableFindings(
        "CornealTopography",
        "left",
        "OS"
      );
      pdfobj.AnteriorSegmentODfindings = this.getLableFindings(
        "AnteriorSegmentOCT",
        "right",
        "OD"
      );
      pdfobj.AnteriorSegmentOSfindings = this.getLableFindings(
        "AnteriorSegmentOCT",
        "left",
        "OS"
      );
      pdfobj.LensStatusODfindings = this.getLableFindings(
        "LensStatus",
        "right",
        "OD"
      );
      pdfobj.LensStatusOSfindings = this.getLableFindings(
        "LensStatus",
        "left",
        "OS"
      );
      pdfobj.FundusPhotoODfindings = this.getLableFindings(
        "FundusPhoto",
        "right",
        "OD"
      );
      pdfobj.FundusPhotoOSfindings = this.getLableFindings(
        "FundusPhoto",
        "left",
        "OS"
      );
      pdfobj.MacularODfindings = this.getLableFindings(
        "MacularOCT",
        "right",
        "OD"
      );
      pdfobj.MacularOSfindings = this.getLableFindings(
        "MacularOCT",
        "left",
        "OS"
      );
      pdfobj.OpticNerveODfindings = this.getLableFindings(
        "OpticNerveOCT",
        "right",
        "OD"
      );
      pdfobj.OpticNerveOSfindings = this.getLableFindings(
        "OpticNerveOCT",
        "left",
        "OS"
      );
      pdfobj.externalphoto_IMGSOURCE =
        this.externalphoto.value == 0
          ? imgary
          : this.externalphoto.value.slice(0, 1);
      pdfobj.corneaODphoto_IMGSOURCE =
        this.corneaODphoto.value.length == 0
          ? imgary
          : this.corneaODphoto.value.slice(0, 1);
      pdfobj.corneaOSphoto_IMGSOURCE =
        this.corneaOSphoto.value.length == 0
          ? imgary
          : this.corneaOSphoto.value.slice(0, 1);
      pdfobj.AnteriorODphoto_IMGSOURCE =
        this.AnteriorODphoto.value.length == 0
          ? imgary
          : this.AnteriorODphoto.value.slice(0, 1);
      pdfobj.AnteriorOSphoto_IMGSOURCE =
        this.AnteriorOSphoto.value.length == 0
          ? imgary
          : this.AnteriorOSphoto.value.slice(0, 1);
      pdfobj.lensStatus_IMGSOURCE =
        this.lensStatus.value.length == 0
          ? imgary
          : this.lensStatus.value.slice(0, 1);
      pdfobj.FundusODphoto_IMGSOURCE =
        this.FundusODphoto.value.length == 0
          ? imgary
          : this.FundusODphoto.value.slice(0, 1);
      pdfobj.FundusOSphoto_IMGSOURCE =
        this.FundusOSphoto.value.length == 0
          ? imgary
          : this.FundusOSphoto.value.slice(0, 1);
      pdfobj.MacularODphoto_IMGSOURCE =
        this.MacularODphoto.value.length == 0
          ? imgary
          : this.MacularODphoto.value.slice(0, 1);
      pdfobj.MacularOSphoto_IMGSOURCE =
        this.MacularOSphoto.value.length == 0
          ? imgary
          : this.MacularOSphoto.value.slice(0, 1);
      pdfobj.NerveODphoto_IMGSOURCE =
        this.NerveODphoto.value.length == 0
          ? imgary
          : this.NerveODphoto.value.slice(0, 1);
      pdfobj.NerveOSphoto_IMGSOURCE =
        this.NerveOSphoto.value.length == 0
          ? imgary
          : this.NerveOSphoto.value.slice(0, 1);

      // this.reportpdjgeneration(pdfobj).then((res) =>
      //   handleFilesSelected([res])
      // );
      this.files.options = {
        feature: "Reports",
        featureId: this.scrId,
        category: "REPORT",
      };

      /*conversion and upload part */

      let options = this.files.options;

      /* blank pdf (res to blob -> file with diret download)*/
      // this.reportpdjgeneration(pdfobj).then((res) => {
      //   const blob = new Blob([res], { type: "application/pdf" });

      //   const file = new File([blob], this.scrId + ".pdf", {
      //     lastModified: new Date().getTime(),
      //     type: "application/pdf",
      //   });
      //   console.log(file, "converted file");
      //   let processedfile = this.handleFilesSelected("REPORT", [file]);
      //   const link = document.createElement("a");
      //   const url = URL.createObjectURL(file);

      //   link.href = url;
      //   link.download = file.name;
      //   document.body.appendChild(link);
      //   link.click();

      //   document.body.removeChild(link);
      //   window.URL.revokeObjectURL(url);
      //   this.handleFilesSend(options, processedfile);
      // });

      /* blank pdf (res to file with direct download)*/
      // this.reportpdjgeneration(pdfobj).then((res) => {
      //   const file = new File([res], this.scrId + ".pdf", {
      //     lastModified: new Date().getTime(),
      //     type: "application/pdf",
      //   });
      //   console.log(file, "converted file");
      //   let processedfile = this.handleFilesSelected("REPORT", [file]);
      //   const link = document.createElement("a");
      //   const url = URL.createObjectURL(file);

      //   link.href = url;
      //   link.download = file.name;
      //   document.body.appendChild(link);
      //   link.click();

      //   document.body.removeChild(link);
      //   window.URL.revokeObjectURL(url);
      //   this.handleFilesSend(options, processedfile);
      // });

      // blank pdf (res to file without diret download)
      this.app.isLoading = true;
      this.reportpdjgeneration(pdfobj)
        .then((res) => {
          const file = new File([res.blob], this.scrId + ".pdf", {
            lastModified: new Date().getTime(),
            type: "application/pdf",
          });
          let processedfile = this.handleFilesSelected("REPORT", [file]);
          this.handleFilesSend(options, processedfile);
          this.handleReportgenProcess();
        })
        .finally(() => (this.app.isLoading = false));

      // this.reportpdjgeneration(pdfobj).then((res) => {
      //   res.blob();
      //   console.log(res, "api result");
      //   //const blob = new Blob([res], { type: "application/pdf" });
      //   const file = new File([blob], this.scrId + ".pdf", {
      //     lastModified: new Date().getTime(),
      //     type: "application/pdf",
      //   });
      //   console.log(file, "converted file");
      //   let processedfile = this.handleFilesSelected("REPORT", [file]);
      //   this.handleFilesSend(options, processedfile);
      // });

      // this.reportpdjgeneration(pdfobj).then((res) => {
      //   //const blob = new Blob([res], { type: "application/pdf" });

      //   const file = { res };
      //   // const file = new File([res], this.scrId + ".pdf", {
      //   //   lastModified: new Date().getTime(),
      //   //   type: "application/pdf",
      //   // });
      //   console.log(file, "converted file");
      //   let processedfile = this.handleFilesSelected("REPORT", [file]);
      //   const link = document.createElement("a");
      //   const url = URL.createObjectURL(file);

      //   link.href = url;
      //   link.download = file.name;
      //   document.body.appendChild(link);
      //   link.click();

      //   document.body.removeChild(link);
      //   window.URL.revokeObjectURL(url);
      //   this.handleFilesSend(options, processedfile);
      // });

      //  pdf
      // this.reportpdjgeneration(pdfobj).then((res) => {
      //   //const blob = new Blob([res], { type: "application/pdf" });
      //   const file = new File([res], this.scrId + ".pdf", {
      //     lastModified: new Date().getTime(),
      //     type: "application/pdf",
      //   });
      //   console.log(file, "converted file");
      //   let processedfile = this.handleFilesSelected("REPORT", [{}]);
      //   this.handleFilesSend(options, processedfile);
      // });

      // const file = new File([{}], this.scrId + ".pdf", {
      //   lastModified: new Date().getTime(),
      //   type: "application/pdf",
      // });

      // let processedfile = this.handleFilesSelected("REPORT", [file]);
      // this.handleFilesSend(options, processedfile);

      console.log(pdfobj);
    },
    getConvertedDate() {
      return moment(this.screeninginfo.value.startDate).format(
        "dddd MMMM Do, YYYY"
      );
    },
    generatepdf() {
      //this.$refs.html2Pdf.generatePdf();
      this.geneartionofnewpdf();

      //this.handleReportgenProcess();
      this.showpreviewClick(false);
      this.popcon = false;
    },
    popup() {
      this.popcon = true;
    },
    Cancel() {
      this.popcon = false;
      this.showpreviewClick(false);
    },
  },
};
</script>
<style scoped>
.d-flex {
  padding-top: 30px;
}
</style>
