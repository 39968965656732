<!-- PATIENT SCREENING Info component -->
<template>
  <!-- <div class="steper-content"> -->
  <div>
    <div class="img-thumb scroll">
      <div class="img-thumb-inner">
        <div
          v-for="image in visionixphotos.value"
          :key="image.id"
          style="margin-right: 18px"
        >
          <drag
            :transfer-data="image"
            @dragstart="handleChildDragstart"
            @click="handleImageClick(image.imgLink.href, masterName)"
            class="drop"
          >
            <img
              :src="image.imgLink.href"
              @error="handleImageError"
              name="paceholder"
              style="width: 100px; height: 100px"
            />
          </drag>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <h4 class="title">Patient Information</h4>
        <div>
          <div class="form-layout-horizondal">
            <label>Email</label>
            <md-field v-html="patientinfo.value.email" />
          </div>
          <div class="form-layout-horizondal">
            <label>Phone</label>
            <md-field v-text="patientinfo.value.phone" />
          </div>
          <div class="form-layout-horizondal">
            <label>DOB (Age)</label>
            <md-field
              >{{ patientinfo.value.dob }} ({{
                this.patientinfo.value.age
              }})</md-field
            >
          </div>
          <div class="form-layout-horizondal">
            <label>Gender</label>
            <md-field>{{ patientinfo.value.gender }}</md-field>
          </div>
          <div class="form-layout-horizondal">
            <label>Eyewear</label>
            <md-field>{{ patientinfo.value.eyewear }}</md-field>
          </div>
          <div class="form-layout-horizondal">
            <label>Address</label>
            <md-field>{{ patientinfo.value.address1 }}</md-field>
          </div>
          <div class="form-layout-horizondal">
            <label>City/St/Zip</label>
            <md-field>{{ patientinfo.value.address2 }}</md-field>
          </div>
        </div>
      </div>

      <!--SCREENING INFO-->
      <div class="md-layout-item md-size-50 md-small-size-100">
        <div>
          <h4 class="title">Screening Information</h4>
          <div>
            <div class="form-layout-horizondal">
              <label>Screen Date</label>
              <md-field>{{ screeninginfo.value.startDate }}</md-field>
            </div>
            <div class="form-layout-horizondal">
              <label>Start Date</label>
              <md-field>{{ screeninginfo.value.startDate }}</md-field>
            </div>
            <div class="form-layout-horizondal">
              <label>End Date</label>
              <md-field>{{ screeninginfo.value.endDate }}</md-field>
            </div>
            <div class="form-layout-horizondal">
              <label>Duration(min)</label>
              <md-field>{{ screeninginfo.value.durationInMins }}</md-field>
            </div>
            <div class="form-layout-horizondal">
              <label>Globe ID</label>
              <md-field>{{ screeninginfo.value.deviceName }}</md-field>
            </div>
            <div class="form-layout-horizondal">
              <label>Location</label>
              <md-field>{{ screeninginfo.value.location }}</md-field>
            </div>
            <div class="form-layout-horizondal">
              <label>Technician</label>
              <md-field>{{ screeninginfo.value.technician }}</md-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoPatientScreening",

  inject: [
    "patientinfo",
    "screeninginfo",
    "handleImageClick",
    "visionixphotos",
    "handleImageError",
  ],

  watch: {
    patientinfo: function () {
      console.log("PatientInfo in watch property:", this.patientinfo);
    },
  },

  methods: {
    mouseOver: function () {},
    mouseLeave: function () {},
    handleChildDragstart(image) {
      localStorage.setItem("testObject", JSON.stringify(image));
      let img = this.screeningEvalData.selectedImages.filter(
        (i) =>
          i.screeningType == "External" && i.eyeSection.toLowerCase() == "ou"
      );
      if (img.length > 0) {
        let ind = this.screeningEvalData.selectedImages.findIndex(
          (i) =>
            i.screeningType == "External" && i.eyeSection.toLowerCase() == "ou"
        );
        this.screeningEvalData.selectedImages.splice(ind, 1);
      }
      this.screeningEvalData.selectedImages.push(image);
      //event.stopPropagation();
    },
  },
};
</script>
