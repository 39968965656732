<template>
  <findingsHandler
    :masterName="masterName"
    :page="page"
    v-model="findings.selectedFindings"
    :savedFindings="findings.savedFindings"
    :screeningId="manageScreening.screeningId"
  >
    <div id="nerve-photo">
      <div class="md-layout">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <div class="card-column">
            <label class="title-header">OD</label>
            <div class="img-thumb scroll" v-if="odImages.length > 1">
              <div class="img-thumb-inner">
                <div v-for="image in odImages" :key="image.id">
                  <drag
                    :transfer-data="image"
                    @dragstart="handleODImageDrag"
                    @dragend="handleODImageDragEnd()"
                    class="drop"
                  >
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      name="paceholder"
                    />
                  </drag>
                </div>
              </div>
            </div>
            <div class="photo-container">
              <drop class="drop" @drop="handleODImageDrop()" v-if="odImage">
                <img
                  :src="odImage.imgLink.href"
                  name="paceholder"
                  @error="handleImageError"
                  @click="handleImageClick(odImage.imgLink.href, masterName)"
                />
              </drop>

              <div v-else>
                <img src="@/assets/img/imageNA.jpg" name="paceholder" />
              </div>
            </div>
            <div class="md-layout-item md-medium-100">
              <field-findings-layout field="right" />
            </div>
            <div class="border-padding">
              <div class="label scroll" style="color: black">
                {{ this.getLableFindings(this.masterName, "right", "OD") }}
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <div class="card-column">
            <label class="title-header">OS</label>
            <div class="img-thumb scroll" v-if="osImages.length > 1">
              <div class="img-thumb-inner">
                <div v-for="image in osImages" :key="image.id">
                  <drag
                    :transfer-data="image"
                    @dragstart="handleOSImageDrag"
                    @dragend="handleOSImageDragEnd()"
                    class="drop"
                  >
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      name="paceholder"
                    />
                  </drag>
                </div>
              </div>
            </div>
            <div class="photo-container">
              <drop class="drop" @drop="handleOSImageDrop()" v-if="osImage">
                <img
                  :src="osImage.imgLink.href"
                  name="paceholder"
                  @error="handleImageError"
                  @click="handleImageClick(osImage.imgLink.href, masterName)"
                />
              </drop>

              <div v-else>
                <img src="@/assets/img/imageNA.jpg" name="paceholder" />
              </div>
            </div>
            <div class="md-layout-item md-medium-100">
              <field-findings-layout field="left" />
            </div>
            <div class="border-padding">
              <div class="label scroll" style="color: black">
                {{ this.getLableFindings(this.masterName, "left", "OS") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <findings-button />
  </findingsHandler>
</template>

<script>
import findingsHandler from "@/handlers/module-handlers/findings/findingsHandler.js";
import { Drop, Drag } from "vue-drag-drop";
import FindingsButton from "./findings/FindingsButton.vue";
import FieldFindingsLayout from "./findings/FieldFindingsLayout.vue";
export default {
  data() {
    return {
      masterName: "AnteriorSegmentOCT",
      page: "screeningeval",
      draggedImageOD: null,
      draggedImageOS: null,
    };
  },
  computed: {
    osImages: function () {
      if (this.AnteriorOSphotos.value && this.AnteriorOSphotos.value.length > 0)
        return this.AnteriorOSphotos.value.filter(
          (x) => this.isScreeningImage(x) && x.eyeSection.toLowerCase() == "os"
        );
      return [];
    },
    osImage: function () {
      if (
        this.AnteriorOSphoto.value &&
        this.AnteriorOSphoto.value.length > 0 &&
        this.isScreeningImage(this.AnteriorOSphoto.value[0]) &&
        this.AnteriorOSphoto.value[0].eyeSection.toLowerCase() == "os"
      )
        return this.AnteriorOSphoto.value[0];
      else if (this.osImages.length > 0) return this.osImages[0];
      return null;
    },
    odImages: function () {
      if (this.AnteriorODphotos.value && this.AnteriorODphotos.value.length > 0)
        return this.AnteriorODphotos.value.filter(
          (x) => this.isScreeningImage(x) && x.eyeSection.toLowerCase() == "od"
        );
      return [];
    },
    odImage: function () {
      if (
        this.AnteriorODphoto.value &&
        this.AnteriorODphoto.value.length > 0 &&
        this.isScreeningImage(this.AnteriorODphoto.value[0]) &&
        this.AnteriorODphoto.value[0].eyeSection.toLowerCase() == "od"
      )
        return this.AnteriorODphoto.value[0];
      else if (this.odImages.length > 0) return this.odImages[0];
      return null;
    },
  },
  components: {
    drop: Drop,
    drag: Drag,
    findingsHandler,
    FindingsButton,
    FieldFindingsLayout,
  },
  inject: [
    "AnteriorSegment",
    "handleDrop",
    "AnteriorODphoto",
    "AnteriorOSphoto",
    "AnteriorODphotos",
    "AnteriorOSphotos",
    "findings",
    "getLableFindings",
    "handleImageClick",
    "handleChildDragstart",
    "findingsMaster",
    "handleImageError",
    "manageScreening",
    "screeningEvalData",
    "isScreeningImage",
  ],
  methods: {
    handleODImageDrag(image) {
      this.draggedImageOD = image;
    },
    handleODImageDrop() {
      if (this.draggedImageOD) {
        this.AnteriorODphoto.value = [this.draggedImageOD];
        let img = this.screeningEvalData.selectedImages.filter(
          (i) =>
            i.screeningType == this.draggedImageOD.screeningType &&
            i.eyeSection.toLowerCase() ==
              this.draggedImageOD.eyeSection.toLowerCase()
        );
        if (img.length > 0) {
          let ind = this.screeningEvalData.selectedImages.findIndex(
            (i) =>
              i.screeningType == img[0].screeningType &&
              i.eyeSection.toLowerCase() == img[0].eyeSection.toLowerCase()
          );
          this.screeningEvalData.selectedImages.splice(ind, 1);
        }
        this.screeningEvalData.selectedImages.push(this.draggedImageOD);
      }
    },
    handleODImageDragEnd() {
      this.draggedImageOD = null;
    },

    handleOSImageDrag(image) {
      this.draggedImageOS = image;
    },
    handleOSImageDrop() {
      if (this.draggedImageOS) {
        this.AnteriorOSphoto.value = [this.draggedImageOS];
        let img = this.screeningEvalData.selectedImages.filter(
          (i) =>
            i.screeningType == this.draggedImageOS.screeningType &&
            i.eyeSection.toLowerCase() ==
              this.draggedImageOS.eyeSection.toLowerCase()
        );
        if (img.length > 0) {
          let ind = this.screeningEvalData.selectedImages.findIndex(
            (i) =>
              i.screeningType == img[0].screeningType &&
              i.eyeSection.toLowerCase() == img[0].eyeSection.toLowerCase()
          );
          this.screeningEvalData.selectedImages.splice(ind, 1);
        }
        this.screeningEvalData.selectedImages.push(this.draggedImageOS);
      }
    },
    handleOSImageDragEnd() {
      this.draggedImageOS = null;
    },
  },
};
</script>
