<template>
  <fragment>
    <div class="card-icon">
      <i class="fa fa-globe"></i>
    </div>
    <fragment v-if="featureDetails.templateCardTitle">
      <slot name="title"></slot>
    </fragment>
    <h4 class="title" v-else>{{ title || featureDetails.title }}</h4>
  </fragment>
</template>
<script>
export default {
  props: ["title"],
  inject: ["featureDetails"],
};
</script>
