<template>
  <list-layout :hasHeader="false" :isReadonly="isEvaluated.isReadOnly">
    <template v-slot:content>
      <referral-doctors-table />
    </template>
  </list-layout>
</template>

<script>
import ReferralDoctorsTable from "../Tables/ReferralDoctorsTable.vue";
import ListLayout from "../../layouts/list-layout/ListLayout.vue";
export default {
  name: "ReferralDoctorsPage",
  components: {
    ReferralDoctorsTable,
    ListLayout,
  },
  inject: ["isEvaluated"],
};
</script>
