<template>
  <div>
    <div class="steper-content">
      <div id="vision">
        <div>
          <div class="d-flex">
            <div class="form-layout-horizondal-double">
              <label></label>
              <div class="form-layout-double">
                <h4 class="title w-50">OD</h4>
                <h4 class="title w-50">OS</h4>
              </div>
            </div>
          </div>
          <div>
            <div class="title-group">Visual Acuity</div>
            <div class="form-layout-horizondal-double">
              <label
                >Distance (
                {{
                  screeningVision.value.vision_acuity_with_correctionD ==
                    "n/a" ||
                  screeningVision.value.vision_acuity_with_correctionD == null
                    ? "sc"
                    : "cc"
                }}) :
              </label>
              <div class="form-layout-double">
                <TextInput
                  v-model="screeningVision.value.va_od_dist"
                  :readonly="isEvaluated.isReadOnly"
                />
                <TextInput
                  v-model="screeningVision.value.va_os_dist"
                  :readonly="isEvaluated.isReadOnly"
                />
              </div>
            </div>
            <div class="form-layout-horizondal-double">
              <label
                >Near (
                {{
                  screeningVision.value.vision_acuity_with_correctionN ==
                    "n/a" ||
                  screeningVision.value.vision_acuity_with_correctionN == null
                    ? "sc"
                    : "cc"
                }}) :</label
              >
              <div class="form-layout-double">
                <TextInput
                  v-model="screeningVision.value.va_od_near"
                  :readonly="isEvaluated.isReadOnly"
                />
                <TextInput
                  v-model="screeningVision.value.va_os_near"
                  :readonly="isEvaluated.isReadOnly"
                />
              </div>
            </div>
            <div class="form-layout-horizondal-double">
              <label>Color:</label>
              <div class="form-layout-double">
                <TextInput
                  v-model="screeningVision.value.va_od_color"
                  :readonly="isEvaluated.isReadOnly"
                />
                <TextInput
                  v-model="screeningVision.value.va_os_color"
                  :readonly="isEvaluated.isReadOnly"
                />
              </div>
            </div>
            <div class="form-layout-horizondal-double">
              <label>Stereo (Binocular):</label>
              <div class="form-layout-double">
                <TextInput
                  v-model="screeningVision.value.va_od_stereo"
                  :readonly="isEvaluated.isReadOnly"
                />
                <TextInput
                  v-model="screeningVision.value.va_os_stereo"
                  :readonly="isEvaluated.isReadOnly"
                />
              </div>
            </div>

            <div class="title-group">Anterior Segment</div>
            <div class="form-layout-horizondal-double">
              <label>Angle:</label>
              <div class="form-layout-double">
                <TextInput
                  v-model="screeningVision.value.ant_od_ang"
                  :readonly="isEvaluated.isReadOnly"
                />
                <TextInput
                  v-model="screeningVision.value.ant_os_ang"
                  :readonly="isEvaluated.isReadOnly"
                />
              </div>
            </div>
            <div class="form-layout-horizondal-double">
              <label>Thickness:</label>
              <div class="form-layout-double">
                <TextInput
                  v-model="screeningVision.value.ant_od_thick"
                  :readonly="isEvaluated.isReadOnly"
                />
                <TextInput
                  v-model="screeningVision.value.ant_os_thick"
                  :readonly="isEvaluated.isReadOnly"
                />
              </div>
            </div>
            <div class="title-group">Intra Ocular Pressure</div>
            <div class="form-layout-horizondal-double">
              <label>Eye Pressure:</label>
              <div class="form-layout-double">
                <TextInput
                  :class="
                    screeningVision.value.iop_od_eyepres > 21.0 &&
                    'md-input-alert'
                  "
                  v-model="screeningVision.value.iop_od_eyepres"
                  :readonly="isEvaluated.isReadOnly"
                />
                <TextInput
                  :class="
                    screeningVision.value.iop_os_eyepres > 21.0 &&
                    'md-input-alert'
                  "
                  v-model="screeningVision.value.iop_os_eyepres"
                  :readonly="isEvaluated.isReadOnly"
                />
              </div>
            </div>
            <div class="form-layout-horizondal-double">
              <label>Corrected IOP:</label>
              <div class="form-layout-double">
                <TextInput
                  :class="
                    screeningVision.value.iop_od_correct > 21.0 &&
                    'md-input-alert'
                  "
                  v-model="screeningVision.value.iop_od_correct"
                  :readonly="isEvaluated.isReadOnly"
                />
                <TextInput
                  :class="
                    screeningVision.value.iop_os_correct > 21.0 &&
                    'md-input-alert'
                  "
                  v-model="screeningVision.value.iop_os_correct"
                  :readonly="isEvaluated.isReadOnly"
                />
              </div>
            </div>
            <div class="title-group">Auto Refraction</div>
            <div class="form-layout-horizondal-double">
              <label></label>
              <div class="form-layout-double">
                <div class="form-layout-segment">
                  <div>
                    <u>Sph</u>
                    <div>{{ screeningVision.value.ref_od_sph }}</div>
                  </div>
                  <div>
                    <u>Cyl</u>
                    <div>{{ screeningVision.value.ref_od_cyl }}</div>
                  </div>
                  <div>
                    <u>Ax</u>
                    <div>{{ screeningVision.value.ref_od_axis }}</div>
                  </div>
                </div>
                <div class="form-layout-segment">
                  <div>
                    <u>Sph</u>
                    <div>{{ screeningVision.value.ref_os_sph }}</div>
                  </div>
                  <div>
                    <u>Cyl</u>
                    <div>{{ screeningVision.value.ref_os_cyl }}</div>
                  </div>
                  <div>
                    <u>Ax</u>
                    <div>{{ screeningVision.value.ref_os_axis }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screeningService from "../../../services/module-services/screeningeval/ScreeningevalService";
import TextInput from "../../controls/TextInput.vue";
export default {
  mixin: [screeningService],
  inject: ["screeningVision", "isEvaluated"],

  name: "ScreeningVision",
  components: {
    TextInput,
  },
};
</script>
