<template>
  <div class="steper-content">
    <div id="diagnoses">
      <h4 class="title">Does Not Render</h4>
      <md-field>
        <md-textarea
          :readonly="true"
          v-model="eyepressureAndDiagnosis"
        ></md-textarea>
      </md-field>
    </div>
  </div>
</template>

<script>
export default {
  inject: [
    "findings",
    "isEvaluated",
    "screeningVision",
    "selecteddiagnosis",
    "selectedrecommendation",
    "diagnosisComments",
    "recommendationComments",
  ],

  computed: {
    eyepressureAndDiagnosis: function () {
      // if (
      //   this.screeningVision.value.iop_od_eyepres > 21.0 ||
      //   this.screeningVision.value.iop_os_eyepres > 21.0 ||
      //   this.screeningVision.value.iop_od_correct > 21.0 ||
      //   this.screeningVision.value.iop_os_correct > 21.0
      // )
      //   return "Pressure greater than 21, " + this.selecteddiagnosis.value;
      return this.selecteddiagnosis.value;
    },
  },
};
</script>

<style></style>
