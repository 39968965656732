<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-medium-size-50 md-small-size-100">
            <div class="card-border">
              <common-findings
                :field="field"
                :isReadOnly="isEvaluated.isReadOnly"
              />
            </div>
          </div>

          <div class="md-layout-item md-medium-size-50 md-small-size-100">
            <div class="card-border">
              <additional-findings
                :field="field"
                :isReadOnly="isEvaluated.isReadOnly"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="md-layout-item md-size-60">
        <label for="diagExtOD">Center</label>
      </div> -->
    </div>
  </div>
</template>

<script>
import AdditionalFindings from "@/components/Globechek/Findings/AdditionalFindings";
import CommonFindings from "@/components/Globechek/Findings/CommonFindings.vue";

export default {
  props: ["field"],
  components: {
    CommonFindings,
    AdditionalFindings,
  },
  inject: ["findingsMaster", "isEvaluated"],
};
</script>
