import ReportService from "../../../services/module-services/reports/reportsService";

const ReportGenHandler = {
  mixins: [ReportService],
  inject: ["showpreviewClick", "confirmation", "prescriptionData"],
  methods: {
    handleReportgenProcess() {
      this.reportgenprocess();
    },
    handleUpdateReportStatus(screeningId) {
      this.UpdateReportStatus(screeningId);
    },
    handleResendReport(screeningId) {
      this.ResendReport(screeningId);
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
  template: `<div><slot></slot></div>`,
  provide() {
    return {
      handleReportgenProcess: this.handleReportgenProcess,
      handleUpdateReportStatus: this.handleUpdateReportStatus,
      handleResendReport: this.handleResendReport,
    };
  },
};

export default ReportGenHandler;

export const withReportGenHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ReportGenHandler><WrappedComponent v-bind="$props"></WrappedComponent></ReportGenHandler>`,
    components: { ReportGenHandler, WrappedComponent },
  };
};
