import ManageFindingsService from "../../../services/module-services/findings/ManageFindingsService";

const ManageFindingsHandler = {
  props: ["type", "findingsId"],
  mixins: [ManageFindingsService],
  inject: [],
  created() {
    this.findings.postData.findingsID = this.findingsId;
    this.findings.type = this.type;
    this.loadAdditionFindingsGroupList();
    if (this.findings.postData.findingsID) this.loadFindings();
    if (location.pathname.includes("/mysettings")) {
      this.manageFindings.page = "mysettings";
    }
  },
  methods: {
    async handleSave() {
      if (!(await this.findings.isFormValid())) return;
      if (this.findings.postData.findingsID) {
        let selectedFindings = this.commonFindings.list.filter(
          (x) =>
            x.findingsID == this.findings.postData.findingsID ||
            String(x.findingsName).toLowerCase() ==
              String(this.findings.postData.findingsName).toLowerCase()
        );
        selectedFindings = selectedFindings.concat(
          this.additionalFindings.list.filter(
            (x) =>
              x.findings.find(
                (y) =>
                  String(y.findingsName).toLowerCase() ==
                    String(this.findings.postData.findingsName).toLowerCase() ||
                  y.findingsID == this.findings.postData.findingsID
              ) != null
          )
        );
        if (selectedFindings.length > 1) {
          this.findings.errors.findingsName =
            "Findings Name already exists in this section";
          return;
        }
      } else {
        let selectedFindings = this.commonFindings.list.find(
          (x) =>
            String(x.findingsName).toLowerCase() ==
            String(this.findings.postData.findingsName).toLowerCase()
        );
        if (selectedFindings == null)
          selectedFindings = this.additionalFindings.list.find((x) =>
            x.findings.find(
              (y) =>
                String(y.findingsName).toLowerCase() ==
                String(this.findings.postData.findingsName).toLowerCase()
            )
          );
        if (selectedFindings) {
          this.findings.errors.findingsName =
            "Findings Name already exists in this section";
          return;
        }
      }
      this.findings.errors.findingsName = "";
      this.saveFindings();
    },
  },
  provide() {
    return {
      handleSave: this.handleSave,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default ManageFindingsHandler;

export const withManageFindingsHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ManageFindingsHandler><WrappedComponent v-bind="$props"></WrappedComponent></ManageFindingsHandler>`,
    components: { ManageFindingsHandler, WrappedComponent },
  };
};
