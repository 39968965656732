var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fragment',[_c('label',{staticClass:"title-label"},[_vm._v("Common Findings")]),_c('div',{staticClass:"checkbox-list-no-border scroll"},[_c('check-box-input',{attrs:{"options":_vm.commonFindings.list
            .filter(
              function (x) { return x.eyeSection == 'Both' || x.eyeSection == _vm.eyeSection; }
            )
            .map(function (x) { return ({
              value: x.findingsID,
              text: x.findingsName,
            }); }),"isDisabled":_vm.isReadOnly},on:{"onChange":_vm.handleFindingsChange},model:{value:(_vm.selectedCommonFindings[_vm.field]),callback:function ($$v) {_vm.$set(_vm.selectedCommonFindings, _vm.field, $$v)},expression:"selectedCommonFindings[field]"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }