<template>
  <div>
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>
    <div class="md-layout">
      <div class="md-layout-item">
        <h3 class="md-title m-0">Screening Evaluation</h3>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header
            class="profile-header justify-content-between card-sub-header"
          >
            <div class="d-flex">
              <div class="card-icon">
                <i class="fa fa-2x fa-file" />
              </div>
              <div>
                <h4 class="title m-0">Diagnose Screening Results</h4>
                <span>Generate Screening Report</span>
              </div>
            </div>

            <div class="button-container">
              <!-- <md-button
                v-if="!isEvaluated.isReadOnly"
                class="md-danger-outline"
                @click="showpreviewClick(true)"
                ><i class="fa fa-eye"></i>
                Preview Report
              </md-button> -->
              <div v-if="this.page == 'edit'"></div>
              <div v-else class="pt-3 pt-4">
                <md-button
                  v-if="!isEvaluated.isReadOnly || !isEvaluated.istechnotes"
                  class="md-primary"
                  @click="handleSaveScreening"
                  >Save</md-button
                >
                <!-- <div v-if="isEvaluated.reqflag">
                  <md-button
                    class="md-danger-outline"
                    v-if="
                      this.screeninginfo.value.doctorID !=
                      this.app.userProfile.id
                    "
                    style="width: 90%"
                    @click="handlerequesttorelease"
                    >Request Release</md-button
                  >
                  <md-button
                    class="md-green-outline"
                    v-else
                    style="width: 90%"
                    @click="handlereleaseforreq"
                    >Release</md-button
                  >
                </div> -->

                <div
                  v-if="
                    isEvaluated.reqflag &&
                    userClaims.includes(Permissions.ReaderRequestRelease)
                  "
                >
                  <md-button
                    class="md-danger-outline"
                    v-if="
                      this.screeninginfo.value.doctorID !=
                      this.app.userProfile.id
                    "
                    style="width: 90%"
                    @click="handlerequesttorelease"
                    >Request Release</md-button
                  >
                  <md-button
                    class="md-green-outline"
                    v-else
                    style="width: 90%"
                    @click="handlereleaseforreq"
                    >Release</md-button
                  >
                </div>
                <div v-else-if="isEvaluated.reqflag">
                  <md-button
                    class="md-green-outline"
                    style="width: 90%"
                    @click="handlereleaseforreq"
                    >Release</md-button
                  >
                </div>
              </div>
              <md-button
                class="btn-outline"
                @click="collapseall()"
                v-if="forceActive"
                >Collapse All</md-button
              >
              <md-button
                class="btn-outline"
                @click="expandall()"
                v-if="!forceActive"
                >Expand All</md-button
              >
            </div>
          </md-card-header>
          <div class="vsa-list-container">
            <vsa-list>
              <vsa-item ref="patientInfo" :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-user"></i>
                  <span>{{ patientinfo.value.name }}</span>
                </vsa-heading>

                <vsa-content>
                  <InfoPatientScreening />
                  <hr />
                  <InfoScreeningHistory />
                </vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-clipboard-check"></i>
                  NOTE
                </vsa-heading>
                <vsa-content><note /> </vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-clipboard-check"></i>
                  VA, IOP, ANT SEG OCT
                </vsa-heading>
                <vsa-content><Infovision /> </vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-clipboard-check"></i>
                  PRESCRIPTION
                </vsa-heading>
                <vsa-content> <Prescription /></vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-eye"></i>
                  EXTERNAL PHOTO
                </vsa-heading>
                <vsa-content><ExternalPhotoFindings /> </vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-eye"></i>
                  CORNEAL TOPOGRAPHY
                </vsa-heading>
                <vsa-content><corneal-photo /> </vsa-content>
              </vsa-item>
            </vsa-list>

            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-eye"></i>
                  ANTERIOR SEGMENT OCT
                </vsa-heading>
                <vsa-content><anterio-segment-oct /> </vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-eye"></i>
                  Lens Status
                </vsa-heading>
                <vsa-content> <LensStatus /></vsa-content>
              </vsa-item>
            </vsa-list>

            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-eye"></i>
                  FUNDUS PHOTO
                </vsa-heading>
                <vsa-content> <Fundusphoto /></vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-eye"></i>
                  MACULAR OCT
                </vsa-heading>
                <vsa-content> <Macularphoto /></vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-eye"></i>
                  OPTIC NERVE OCT
                </vsa-heading>
                <vsa-content> <Nervephoto /></vsa-content>
              </vsa-item>
            </vsa-list>

            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-align-center"></i>
                  FINDINGS
                </vsa-heading>
                <vsa-content> <Recommendation /></vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-align-center"></i>
                  DIAGNOSIS AND RECOMMENDATIONS
                </vsa-heading>
                <vsa-content> <RecommendationComments /></vsa-content>
              </vsa-item>
            </vsa-list>
            <vsa-list>
              <vsa-item :forceActive="forceActive">
                <vsa-heading>
                  <i class="fa fa-user-md"></i>
                  REFERRAL DOCTORS
                </vsa-heading>
                <vsa-content
                  ><PatientProviderModuleHandler :patientinfo="patientinfo">
                    <ProviderListHandler>
                      <ReferralDoctorsPage />
                    </ProviderListHandler>
                  </PatientProviderModuleHandler>
                </vsa-content>
              </vsa-item>
            </vsa-list>
          </div>
          <md-card-header
            class="profile-header justify-content-end card-sub-header"
          >
            <div class="button-container">
              <md-button
                v-if="!isEvaluated.isReadOnly"
                class="md-danger-outline"
                @click="showpreviewClick(true)"
                ><i class="fa fa-eye"></i>
                Preview Report
              </md-button>
            </div>
          </md-card-header>
        </md-card>
        <DemoReport />
      </div>
    </div>
    <image-modal
      :show="imageView.show"
      :image="imageView.image"
      @onClose="handleCloseImageView"
    />
    <!-- <p>myscreeningpage</p> -->
    <!-- <p>{{ this.screeningID }}</p> -->
    <!-- <ScreeningImages id="screening-images" /> -->
  </div>
</template>

<script>
// import ScreeningImages from "@/components/Globechek/screeningeval/screening-images";
// import ExternalPhoto from "@/components/Globechek/screeningeval/external-photo";
// import OpacityPhoto from "@/components/Globechek/screeningeval/opacity-photo";
//
import InfoPatientScreening from "@/components/Globechek/screeningeval/info-screening-patient";
import InfoScreeningHistory from "@/components/Globechek/screeningeval/info-screening-history";

import Infovision from "@/components/Globechek/screeningeval/info-vision.vue";

import ExternalPhotoFindings from "@/components/Globechek/screeningeval/externalphoto-findings";
import Prescription from "@/components/Globechek/screeningeval/prescription.vue";
import Fundusphoto from "@/components/Globechek/screeningeval/fundus-photo.vue";
import Nervephoto from "@/components/Globechek/screeningeval/Nerve-photo.vue";
import Macularphoto from "@/components/Globechek/screeningeval/Macular-photo.vue";
import CornealPhoto from "../../components/Globechek/screeningeval/corneal-photo.vue";
import PatientProviderModuleHandler from "../../handlers/module-handlers/providers/PatientProviderModuleHandler";
import ReferralDoctorsPage from "../../components/Globechek/screeningeval/ReferralDoctorsPage.vue";
import Recommendation from "../../components/Globechek/screeningeval/recommendation.vue";
import LensStatus from "@/components/Globechek/screeningeval/lensphoto-findings.vue";
import RecommendationComments from "../../components/Globechek/screeningeval/recommendationComments.vue";
//import PreviewReport from "../../components/Globechek/screeningeval/preview-report.vue";
import AnterioSegmentOct from "../../components/Globechek/screeningeval/AnterioSegmentOct.vue";
import ImageModal from "../../components/Globechek/screeningeval/findings/imageModal.vue";
import note from "../../components/Globechek/screeningeval/notes.vue";
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  // VsaIcon,
} from "vue-simple-accordion";
import "vue-simple-accordion/dist/vue-simple-accordion.css";
import DemoReport from "../../components/Globechek/screeningeval/Demorpt.vue";
import { withReportGenHandler } from "../../handlers/module-handlers/reports/reportgenHandler";
import ProviderListHandler from "../../handlers/module-handlers/providers/ProviderListHandler";
import { mapState } from "vuex";

export default {
  inject: [
    "handleSaveScreening",
    "previewReport",
    "showpreviewClick",
    "patientinfo",
    "imageView",
    "handleCloseImageView",
    "progress",
    "app",
    "isEvaluated",
    "handlerequesttorelease",
    "handlereleaseforreq",
    "screeninginfo",
    "Permissions",
  ],
  name: "ScreeningsEval",
  data() {
    return {
      forceActive: true,
      page: "",
    };
  },

  watch: {
    "patientinfo.value.name"() {
      this.$refs.patientInfo.$children[0].$children[0].$children[0].$el.children[1].innerText =
        this.patientinfo.value.name;
    },
  },
  components: {
    // ScreeningImages,
    // OpacityPhoto,
    //
    InfoPatientScreening,
    InfoScreeningHistory,
    Infovision,
    ExternalPhotoFindings,
    Fundusphoto,
    RecommendationComments,
    Nervephoto,
    Macularphoto,
    Prescription,
    CornealPhoto,
    PatientProviderModuleHandler,
    ReferralDoctorsPage,
    ProviderListHandler,
    Recommendation,
    LensStatus,
    //PreviewReport,
    DemoReport: withReportGenHandler(DemoReport),
    AnterioSegmentOct,
    ImageModal,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    note,
    // VsaIcon,
  },
  computed: {
    ...mapState({
      userClaims: (state) => state.profile.userClaims,
    }),
  },
  created() {
    if (location.pathname.includes("Reports/Edit/")) {
      this.page = "edit";
    }
  },
  methods: {
    collapseall() {
      this.forceActive = false;
    },
    ImageModalyPhotocreated() {
      console.log("Hello");
    },
    expandall() {
      this.forceActive = true;
    },
  },
};
</script>
