<template>
  <fragment>
    <div>
      <label class="title-label">Additional Findings</label>
      <div class="checkbox-list-no-border scroll">
        <div v-for="option in additionalFindings.list" :key="option.groupID">
          <div v-if="isVisible(option)">
            <div class="list-title">{{ option.groupName }}</div>
            <check-box-input
              :options="
                option.findings
                  .filter(
                    (x) => x.eyeSection == 'Both' || x.eyeSection == eyeSection
                  )
                  .map((x) => ({
                    value: x.findingsID,
                    text: x.findingsName,
                  }))
              "
              @onChange="handleFindingsChange"
              v-model="selectedAdditionalFindings[field]"
              :isDisabled="isReadOnly"
            >
            </check-box-input>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

//
<script>
export default {
  props: ["field", "isReadOnly"],
  data() {
    return {
      eyeSection: this.field == "right" ? "OD" : "OS",
    };
  },
  inject: [
    "additionalFindings",
    "selectedAdditionalFindings",
    "handleAdditionalFindingsChange",
    // "handleManageFindingsClick",
  ],
  methods: {
    getItemStyle(findings) {
      let style = "margin:0px;";
      if (
        this.selectedAdditionalFindings[this.field].includes(
          findings.findingsID
        )
      )
        style += "color:#1565c0";
      return style;
    },
    handleFindingsChange() {
      this.handleAdditionalFindingsChange(this.field);
    },
    // handleClickItem(findings) {
    //   if (
    //     this.selectedAdditionalFindings[this.field].includes(
    //       findings.findingsID
    //     )
    //   )
    //     this.selectedAdditionalFindings[this.field] =
    //       this.selectedAdditionalFindings[this.field].filter(
    //         (x) => x != findings.findingsID
    //       );
    //   else
    //     this.selectedAdditionalFindings[this.field].push(findings.findingsID);
    //   this.handleAdditionalFindingsChange(this.field);
    // },
    isVisible(option) {
      var findings = option.findings.filter(
        (x) => x.eyeSection == "Both" || x.eyeSection == this.eyeSection
      );
      return findings.length > 0;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
.list-title {
  padding-left: 10px;
}
</style>
