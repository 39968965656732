validation-listener
<template>
  <div>
    <div class="modal-header">{{ title }}</div>
    <div class="modal-content scroll">
      <div class="form-vertical m-0">
        <validation-listener
          @getIsValid="(isValid) => (findings.isFormValid = isValid)"
        >
          <div>
            <validation-handler rules="required" v-slot="{ error }">
              <check-box-input
                :options="[
                  { value: 'OD', text: 'OD' },
                  { value: 'OS', text: 'OS' },
                  { value: 'Both', text: 'OU' },
                ]"
                v-model="findings.postData.eyeSection"
                :error="error"
              />
            </validation-handler>
          </div>
          <div class="mb-3" v-if="findings.type == 'Additional'">
            <span v-if="findings.selectedGroup"
              ><b>Group: </b>{{ findings.selectedGroup.groupName }}</span
            >
            <validation-handler rules="required" v-slot="{ error }" v-else>
              <select-input
                displayName="Group Name"
                :options="
                  findings.groupList.map((x) => ({
                    value: x.groupID,
                    text: x.groupName,
                  }))
                "
                v-model="findings.postData.groupID"
                :required="true"
                :error="error"
              />
            </validation-handler>
          </div>
          <div class="mb-3">
            <validation-handler rules="required" v-slot="{ error }">
              <label>Findings</label>
              <text-input
                label="Add Findings"
                placeholder="Add Finding here"
                :required="true"
                v-model="findings.postData.findingsName"
                :error="error || findings.errors.findingsName"
                class="w-100"
              />
            </validation-handler>
          </div>
          <div class="mb-3">
            <label>Diagnosis</label>
            <textarea
              v-model="findings.postData.diagnosys"
              type="textarea"
              placeholder="Add Diagnosis here"
              class="w-100"
            ></textarea>
          </div>
          <div class="mb-3 w-100">
            <label>Recommendation</label>
            <textarea
              v-model="findings.postData.recommendation"
              type="textarea"
              placeholder="Add Recommendation here"
              class="w-100"
            ></textarea>
          </div>
        </validation-listener>
      </div>
    </div>
    <div class="modal-footer">
      <md-button @click="handleCancel()">Cancel</md-button>
      <md-button class="md-primary" @click="handleSave()">Save</md-button>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["findings", "handleSave", "handleCancel", "findingsMaster"],
  data() {
    return {
      title: "",
    };
  },
  methods: {
    cancel() {
      this.findingsMaster.showManageFindings = false;
    },
  },
  mounted() {
    if (this.findingsMaster.masterName == "ExternalPhoto") {
      this.title = "EXTERNAL PHOTO";
    } else if (this.findingsMaster.masterName == "CornealTopography") {
      this.title = "CORNEAL TOPOGRAPHY";
    } else if (this.findingsMaster.masterName == "AnteriorSegmentOCT") {
      this.title = "ANTERIOR SEGMENT OCT";
    } else if (this.findingsMaster.masterName == "FundusPhoto") {
      this.title = "FUNDUS PHOTO";
    } else if (this.findingsMaster.masterName == "MacularOCT") {
      this.title = "MACULAR OCT";
    } else if (this.findingsMaster.masterName == "LensStatus") {
      this.title = "LENS STATUS";
    } else {
      this.title = "OPTIC NERVE OCT";
    }
  },
};
</script>
