<template>
  <div id="lens-status">
    <div class="md-layout">
      <div class="md-layout-item md-size-20 md-small-size-100">
        <div class="card-column">
          <label class="title-header">OD</label>
          <CommonFindings field="right" :isReadOnly="isEvaluated.isReadOnly" />
          <additional-findings
            field="right"
            :isReadOnly="isEvaluated.isReadOnly"
          />
          <selectedfindings field="right"></selectedfindings>
        </div>
      </div>
      <div class="md-layout-item md-size-60 md-small-size-100">
        <lensStatus />
        <div class="button-container">
          <md-button
            v-show="!isEvaluated.isReadOnly"
            class="md-primary"
            @click="handleManageFindingsClick('Additional')"
            >Add Additional Findings</md-button
          >
          <md-button
            v-show="!isEvaluated.isReadOnly"
            class="md-danger-outline"
            @click="reset()"
          >
            Reset
          </md-button>
          <md-button
            v-if="!isEvaluated.isReadOnly"
            :class="
              findingsMaster.isNormalOUChecked ? 'md-green' : 'md-primary'
            "
            name="normalOU"
            v-bind:value="true"
            @click="normalOuChecked()"
            :disabled="isEvaluated.isReadOnly"
            >Normal OU</md-button
          >
        </div>
      </div>
      <div class="md-layout-item md-size-20 md-small-size-100">
        <div class="card-column">
          <label class="title-header">OS</label>
          <CommonFindings field="left" :isReadOnly="isEvaluated.isReadOnly" />
          <additional-findings
            field="left"
            :isReadOnly="isEvaluated.isReadOnly"
          />
          <selectedfindings field="left"></selectedfindings>
        </div>
      </div>
    </div>
    <md-dialog :md-active.sync="findingsMaster.showManageFindings">
      <ManageFindingsHandler
        :type="findingsMaster.manageFindingsType"
        :findingsId="findingsMaster.manageFindingsId"
      >
        <ManageFindings></ManageFindings>
      </ManageFindingsHandler>
    </md-dialog>
  </div>
</template>

<script>
import CommonFindings from "@/components/Globechek/Findings/CommonFindings.vue";
import AdditionalFindings from "../../Findings/AdditionalFindings.vue";
import ManageFindingsHandler from "@/handlers/module-handlers/findings/ManageFindingsHandler";
import ManageFindings from "@/components/Globechek/Findings/manage-findings/ManageFindings.vue";
import selectedfindings from "../../../Globechek/Findings/SelectedFindingsDetails.vue";
import lensStatus from "@/components/Globechek/screeningeval/lens-status.vue";

export default {
  components: {
    CommonFindings,
    ManageFindingsHandler,
    ManageFindings,
    selectedfindings,
    lensStatus,
    AdditionalFindings,
  },

  inject: [
    "handleManageFindingsClick",
    "findingsMaster",
    "handleFindingsReset",
    "findings",
    "resetSelectedFindings",
    "handleFindingsNormalOU",
    "isEvaluated",
  ],
  methods: {
    reset() {
      this.resetSelectedFindings();
      this.handleFindingsReset();
    },
    normalOuChecked() {
      if (this.findingsMaster.isNormalOUChecked) {
        this.reset();
      } else this.handleFindingsNormalOU();
    },
  },
};
</script>

<style scoped>
.radioLeft {
  display: block;
  text-align: left;
}
.md-radio {
  margin: 0 15px 10px 0;
  padding: 5px 0px 0 10px;
}
.radio-button {
  border: 1px solid #4e4646;
  padding: 5px 0px 0 10px;
  border-radius: 25px;
  width: 134px;
  height: 35px;
  vertical-align: baseline;
  align-items: center;
}
.radio-button:hover {
  background: rgb(216, 214, 214);

  color: rgb(0, 0, 0);
}
</style>
