import { mapActions } from "vuex";
export default {
  inject: [
    "commonFindings",
    "additionalFindings",
    "findingsMaster",
    "showSuccessMsg",
    "showErrorMsg",
    "selectedAdditionalFindings",
    "handleAdditionalFindingsChange",
    "handleload",
  ],
  data() {
    return {
      findings: { postData: {}, groupList: [], errors: { findingsName: "" } },
      manageFindings: {
        page: "",
      },
    };
  },
  methods: {
    ...mapActions("findings", ["addFindings", "updateFindings"]),
    loadFindings() {
      if (this.findings.type == "Additional") {
        let selectedFindings, selectedGroup;
        for (let x of this.additionalFindings.list) {
          for (let y of x.findings) {
            if (y.findingsID == this.findings.postData.findingsID) {
              selectedFindings = { ...y, groupID: x.groupID };
              selectedGroup = { groupID: x.groupID, groupName: x.groupName };
            }
          }
        }
        if (selectedFindings) {
          this.findings.postData = { ...selectedFindings };
          this.findings.selectedGroup = selectedGroup;
        }
      } else if (this.findings.type == "Common") {
        for (let x of this.commonFindings.list)
          if (x.findingsID == this.findings.postData.findingsID) {
            this.findings.postData = { ...x };
          }
      }
    },
    async saveFindings() {
      let createdFindings;
      if (!this.findings.postData.eyeSection)
        this.findings.postData.eyeSection = "Both";
      this.findings.postData.displayValue = this.findings.postData.findingsName;
      this.findings.postData.findingsGroupID = this.findings.postData.groupID;
      this.findings.postData.findingsMasterID = this.findingsMaster.masterID;
      this.findings.postData.findingsMasterName =
        this.findingsMaster.masterName;
      this.findings.postData.type = this.findings.type;
      this.findings.postData.screeningID = this.findingsMaster.screeningId;
      if (this.findings.postData.findingsID) {
        await this.updateFindings(this.findings.postData)
          .then(() => {
            if (this.manageFindings.page == "mysettings") {
              this.showSuccessMsg(" Finding Updated successfully");
            }
          })
          .catch((error) => {
            this.showErrorMsg(error);
          });
      } else
        await this.addFindings(this.findings.postData)
          .then(async (res) => {
            createdFindings = res;
            if (this.manageFindings.page == "mysettings") {
              this.showSuccessMsg(" Finding Added successfully");
            }
          })
          .catch((error) => {
            this.showErrorMsg(error);
          });
      if (createdFindings != null) {
        if (
          !this.findings.postData.findingsID &&
          this.findingsMaster.screeningId
        ) {
          if (this.findings.postData.eyeSection == "Both") {
            this.selectedAdditionalFindings["left"].push(
              createdFindings.findingsID
            );
            this.selectedAdditionalFindings["right"].push(
              createdFindings.findingsID
            );
            this.handleload(() => {
              this.handleAdditionalFindingsChange("left");
            });
            this.handleload(() => {
              this.handleAdditionalFindingsChange("right");
            });
            this.findingsMaster.showManageFindings = false;
          } else {
            let field =
              this.findings.postData.eyeSection == "OD" ? "right" : "left";
            this.selectedAdditionalFindings[field].push(
              createdFindings.findingsID
            );
            this.handleload(() => {
              this.handleAdditionalFindingsChange(field);
            });
          }
          this.findingsMaster.showManageFindings = false;
        } else {
          this.handleload();
        }
      } else {
        this.findingsMaster.showManageFindings = false;
      }

      this.findingsMaster.showManageFindings = false;
    },
    loadAdditionFindingsGroupList() {
      if (this.findings.type == "Additional") {
        this.findings.groupList = this.additionalFindings.list.map((x) => ({
          groupID: x.groupID,
          groupName: x.groupName,
        }));
      }
    },
  },
  provide() {
    return {
      findings: this.findings,
    };
  },
};
