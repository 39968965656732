<template>
  <!-- MODAL LARGE IMAGE -->
  <md-dialog
    :md-active.sync="show"
    @md-clicked-outside="handleCloseImageView()"
  >
    <span class="close-button topright" @click="handleCloseImageView()"
      >&times;</span
    >
    <div class="modal-size-large">
      <div class="modal-content">
        <div @click="$emit('onClose')">
          <!-- <p>image displayed</p> -->
          <img :src="image" width="100%" @error="handleImageError" />
        </div>
      </div>
    </div>
  </md-dialog>
</template>

<script>
export default {
  props: ["show", "image", "onClose"],
  inject: ["handleCloseImageView", "handleImageError"],
};
</script>
<style scoped>
.close-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.topright {
  position: absolute;
  right: 0;
  font-size: 30px;
  /* background: black; */
  /* opacity: 0.5; */
  padding-right: 8px;
  padding-top: 0px;
  top: 0;
}
</style>
