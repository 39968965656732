<template>
  <div id="external-photo">
    <div class="md-layout">
      <div class="md-layout-item md-size-25 md-small-size-100">
        <div class="card-column">
          <label class="title-header">OD</label>
          <CommonFindings field="right" :isReadOnly="isEvaluated.isReadOnly" />
          <additional-findings
            field="right"
            :isReadOnly="isEvaluated.isReadOnly"
          />
          <selectedfindings field="right"></selectedfindings>
        </div>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <ExternalPhoto />
        <div class="label scroll">
          <div style="color: black">
            {{ this.getLableFindings(this.masterName, "right", "OD") }}
          </div>
          <div style="color: black">
            {{ this.getLableFindings(this.masterName, "left", "OS") }}
          </div>
        </div>
        <div class="button-container">
          <div v-show="!isEvaluated.isReadOnly" class="button-container">
            <md-button
              class="md-primary"
              @click="handleManageFindingsClick('Additional')"
              >Add Additional Findings</md-button
            >
            <md-button class="md-danger-outline" @click="handleFindingsReset()"
              >Reset</md-button
            >
            <md-button
              :class="
                findingsMaster.isNormalOUChecked ? 'md-green' : 'md-primary'
              "
              name="normalOU"
              v-bind:value="true"
              @click="normalOuChecked()"
              :disabled="isEvaluated.isReadOnly"
              >Normal OU</md-button
            >
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-25 md-small-size-100">
        <div class="card-column">
          <label class="title-header" for="diagExtOD">OS</label>
          <CommonFindings field="left" :isReadOnly="isEvaluated.isReadOnly" />
          <additional-findings
            field="left"
            :isReadOnly="isEvaluated.isReadOnly"
          />
          <selectedfindings field="left"></selectedfindings>
        </div>
      </div>
    </div>
    <md-dialog :md-active.sync="findingsMaster.showManageFindings">
      <ManageFindingsHandler
        :type="findingsMaster.manageFindingsType"
        :findingsId="findingsMaster.manageFindingsId"
      >
        <ManageFindings></ManageFindings>
      </ManageFindingsHandler>
    </md-dialog>
  </div>
</template>

<script>
import AdditionalFindings from "@/components/Globechek/Findings/AdditionalFindings";
import CommonFindings from "@/components/Globechek/Findings/CommonFindings.vue";
import ManageFindingsHandler from "@/handlers/module-handlers/findings/ManageFindingsHandler";
import ManageFindings from "@/components/Globechek/Findings/manage-findings/ManageFindings.vue";
import selectedfindings from "@/components/Globechek/Findings/SelectedFindingsDetails.vue";
import ExternalPhoto from "@/components/Globechek/screeningeval/external-photo";

export default {
  data() {
    return {
      masterName: "ExternalPhoto",
      page: "screeningeval",
      button_checked: false,
    };
  },
  inject: [
    "handleManageFindingsClick",
    "findingsMaster",
    "handleFindingsReset",
    "findings",
    "additionalFindings",
    "commonFindings",
    "selectedAdditionalFindings",
    "selectedCommonFindings",
    "getLableFindings",
    "handleFindingsNormalOU",
    "isEvaluated",
  ],
  methods: {
    normalOuChecked() {
      if (this.findingsMaster.isNormalOUChecked) {
        this.handleFindingsReset();
      } else this.handleFindingsNormalOU();
    },
  },
  components: {
    CommonFindings,
    AdditionalFindings,
    ManageFindingsHandler,
    ManageFindings,
    selectedfindings,
    ExternalPhoto,
  },
};
</script>

<style scoped>
.radioLeft {
  display: block;
  text-align: left;
}
.md-radio {
  margin: 0 15px 10px 0;
  padding: 5px 0px 0 10px;
}
.radio-button {
  border: 1px solid #4e4646;
  padding: 5px 0px 0 10px;
  border-radius: 25px;
  width: 134px;
  height: 35px;
  vertical-align: baseline;
  align-items: center;
  background-color: #3b8deb;
}
.radio-button:hover {
  background: white;

  color: rgb(252, 250, 250);
}
</style>
