import ProviderModuleService from "../../../services/module-services/providers/ProviderModuleService";
import ListHandler from "../../common-handlers/ListHandler";

const PatientProviderModuleHandler = {
  mixins: [ProviderModuleService],
  props: ["patientinfo"],
  data() {
    return {
      refreshList: () => {},
      listInputSource: { value: { filter: "", searchText: "" } },
    };
  },
  watch: {
    "patientinfo.value": function () {
      this.processPatientInfoValue();
    },
  },
  methods: {
    handleGetRefreshList(func) {
      this.refreshList = func;
      this.processPatientInfoValue();
    },
    processPatientInfoValue() {
      if (this.patientinfo.value.id) {
        if (this.patientinfo.value.zipcode) {
          this.listInputSource.value = {
            filter: "zip",
            searchText: this.patientinfo.value.zipcode,
          };
        } else {
          this.listFilters.doctorType = "Doctor";
          this.refreshList();
        }
      }
    },
  },
  provide() {
    return {
      getList: this.getListFunc,
      getListCount: this.getListCountFunc,
    };
  },
  template: `<ListHandler :listInputSource="listInputSource"
  :preventDefaultRefresh="true" @getRefreshList="this.handleGetRefreshList"><slot></slot></ListHandler>`,
  components: {
    ListHandler,
  },
};

export default PatientProviderModuleHandler;

export const withPatientProviderModuleHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<PatientProviderModuleHandler><WrappedComponent v-bind="$props"></WrappedComponent></PatientProviderModuleHandler>`,
    components: { PatientProviderModuleHandler, WrappedComponent },
  };
};
