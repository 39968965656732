<template>
  <div class="md-layout md-gutter">
    <!--SEARCH-->
    <div class="md-layout-item md-xsmall-size-100">
      <text-input
        :clearable="true"
        label="Search"
        :placeholder="featureDetails.searchPlaceHolder || 'Search by keywords'"
        v-model="listInput.searchText"
        :autofocus="true"
        :readonly="isReadOnly"
      ></text-input>
    </div>
    <div
      class="md-layout-item md-xsmall-size-100"
      v-if="featureDetails.filterList && featureDetails.filterList.length > 0"
    >
      <select-input
        name="filter"
        displayName="Filter"
        placeHolder="Filter"
        :options="
          featureDetails.filterList.map((x) => ({
            value: x.value,
            text: x.displayValue,
          }))
        "
        v-model="listInput.filter"
        :disabled="isReadOnly"
      />
    </div>
    <div
      class="md-layout-item md-xsmall-size-100"
      v-if="
        featureDetails.title == 'Screenings Available' ||
        featureDetails.title == 'Screenings On-hold' ||
        featureDetails.title == 'Reports'
      "
    >
      <select-input
        name="filter"
        displayName="Filter"
        placeHolder="Filter"
        :options="
          filterVal.map((x) => ({
            value: x.value,
            text: x.displayValue,
          }))
        "
        v-model="listInput.filterText"
        :required="false"
      />
    </div>
    <div
      class="md-layout-item md-xsmall-size-100"
      v-if="featureDetails.title == 'Screenings Available'"
    >
      <select-input
        name="Sort"
        displayName="Sort"
        placeHolder="Sort"
        :options="
          sortVal.map((x) => ({
            value: x.value,
            text: x.displayValue,
          }))
        "
        v-model="listInput.sortText"
        :required="false"
      />
    </div>
    <div
      class="md-layout-item md-xsmall-size-100"
      v-if="featureDetails.title == 'Screenings On-hold'"
    >
      <select-input
        name="Sort"
        displayName="Sort"
        placeHolder="Sort"
        :options="
          holdsortVal.map((x) => ({
            value: x.value,
            text: x.displayValue,
          }))
        "
        v-model="listInput.sortText"
        :required="false"
      />
    </div>
    <div
      class="md-layout-item md-xsmall-size-100"
      v-if="featureDetails.title == 'Reports'"
    >
      <select-input
        name="Sort"
        displayName="Sort"
        placeHolder="Sort"
        :options="
          reportsortVal.map((x) => ({
            value: x.value,
            text: x.displayValue,
          }))
        "
        v-model="listInput.sortText"
        :required="false"
      />
    </div>
    <div class="md-layout-item" style="padding-bottom: 12px">
      <!-- md-large-size-65  -->
      <div style="display: flex; float: right">
        <div style="flex-grow: 1">
          <div class="outline-blue-button">
            <md-button
              toolTip="Refresh List"
              @click="refreshList()"
              style="margin-right: 15px"
              ><i class="fas fa-redo-alt"></i
              ><span class="md-small-hide">refresh</span></md-button
            >
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import PrimaryButton from "../../../components/controls/button/PrimaryButton.vue";
import TextInput from "../../controls/TextInput.vue";
import SelectInput from "../../controls/SelectInput.vue";
import { mapState } from "vuex";

export default {
  inject: ["listInput", "refreshList", "featureDetails"],
  components: { TextInput, SelectInput },
  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      filterVal: (state) => state.screenings.screeningsFilter,
      sortVal: (state) => state.screenings.screeningsSort,
      holdsortVal: (state) => state.screenings.holdSort,
      reportsortVal: (state) => state.reports.reportsSort,
    }),
  },
  created() {
    if (this.$router.currentRoute.name == "Onhold") {
      this.listInput.searchText = this.$router.currentRoute.query.ScrID;
    }
  },
};
</script>
