import {
  getCancel,
  getSubmit,
} from "../../../services/control-services/ConfirmationService";
import FindingService from "../../../services/module-services/findings/findingService";

const findingsHandler = {
  props: ["screeningId", "masterName", "page", "value", "savedFindings"],
  mixins: [FindingService],
  inject: [
    "isEvaluated",
    "confirmation",
    "showErrorMsg",
    "isSuperAdmin",
    "navigationBlocker",
  ],
  // props: ["findingMasterName", "page"],
  created() {
    // this.findingsMaster.masterName = this.findingMasterName;
    this.findingsMaster.page = this.page;
    this.findingsMaster.masterName = this.masterName;
    this.findingsMaster.screeningId = this.screeningId;
    this.loadFindings();
  },
  watch: {
    savedFindings() {
      this.bindFromSavedFindings();
    },
    "findingsMaster.masterID"() {
      this.bindFromSavedFindings();
    },
    screeningId: function () {
      this.findingsMaster.screeningId = this.screeningId;
    },
  },
  methods: {
    handleDeleteFindingsClick(findingsID) {
      this.confirmation.title = "Delete Findings?";
      this.confirmation.description =
        "Are you sure you want to delete this Findings?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.processDeleteFindings(findingsID)
          .then(() => this.loadFindings())
          .catch((error) => {
            this.showErrorMsg(error);
          });
        this.submitSubscription.unsubscribe();
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
    bindFromSavedFindings() {
      if (
        this.findingsMaster.masterID &&
        this.savedFindings &&
        this.savedFindings.length > 0
      ) {
        let selectedFindings = [];
        this.selectedCommonFindings.left = this.savedFindings
          .filter(
            (x) =>
              x.findingsMasterID == this.findingsMaster.masterID &&
              x.field == "left" &&
              x.type == "common"
          )
          .map((x) => x.findingsID);
        this.selectedAdditionalFindings.left = this.savedFindings
          .filter(
            (x) =>
              x.findingsMasterID == this.findingsMaster.masterID &&
              x.field == "left" &&
              x.type == "additional"
          )
          .map((x) => x.findingsID);
        this.selectedAdditionalFindings.right = this.savedFindings
          .filter(
            (x) =>
              x.findingsMasterID == this.findingsMaster.masterID &&
              x.field == "right" &&
              x.type == "additional"
          )
          .map((x) => x.findingsID);
        this.selectedCommonFindings.right = this.savedFindings
          .filter(
            (x) =>
              x.findingsMasterID == this.findingsMaster.masterID &&
              x.field == "right" &&
              x.type == "common"
          )
          .map((x) => x.findingsID);
        selectedFindings = selectedFindings.concat(
          this.commonFindings.list
            .filter((x) =>
              this.selectedCommonFindings.left.includes(x.findingsID)
            )
            .map((x) => ({
              ...x,
              field: "left",
              type: "common",
              findingsMasterName: this.masterName,
            }))
        );

        if (
          this.selectedCommonFindings.left.length == 1 &&
          this.selectedCommonFindings.left.includes(this.normalFindingsId) &&
          this.selectedCommonFindings.right.length == 1 &&
          this.selectedCommonFindings.right.includes(this.normalFindingsId)
        )
          this.findingsMaster.isNormalOUChecked = true;
        else this.findingsMaster.isNormalOUChecked = false;

        for (let option of this.additionalFindings.list)
          for (let findings of option.findings)
            if (
              this.selectedAdditionalFindings.left.includes(findings.findingsID)
            )
              selectedFindings.push({
                ...findings,
                field: "left",
                type: "additional",
                findingsMasterName: this.masterName,
              });
        selectedFindings = selectedFindings.concat(
          this.commonFindings.list
            .filter((x) =>
              this.selectedCommonFindings.right.includes(x.findingsID)
            )
            .map((x) => ({
              ...x,
              field: "right",
              type: "common",
              findingsMasterName: this.masterName,
            }))
        );
        for (let option of this.additionalFindings.list)
          for (let findings of option.findings)
            if (
              this.selectedAdditionalFindings.right.includes(
                findings.findingsID
              )
            )
              selectedFindings.push({
                ...findings,
                field: "right",
                type: "additional",
                findingsMasterName: this.masterName,
              });
        this.$emit("input", this.value.concat(selectedFindings));
      }
    },
    handleManageFindingsClick(type, findingsID) {
      this.findingsMaster.showManageFindings = true;
      this.findingsMaster.manageFindingsType = type;
      this.findingsMaster.manageFindingsId = findingsID;
    },
    handleCommonFindingsChange(option, field) {
      let isChecked = this.selectedCommonFindings[field].includes(option.value);
      let tempValue = [...this.value];
      let tempAdditional = tempValue.filter(
        (x) =>
          x.findingsMasterID == this.findingsMaster.masterID &&
          x.field == field &&
          x.type == "additional"
      );
      tempValue = tempValue.filter(
        (x) =>
          x.findingsMasterID != this.findingsMaster.masterID || x.field != field
      );
      if (isChecked) {
        if (option.value === this.normalFindingsId) {
          this.selectedCommonFindings[field] = [this.normalFindingsId];
          this.selectedAdditionalFindings[field] = [];
        } else if (
          this.selectedCommonFindings[field].includes(this.normalFindingsId)
        )
          this.selectedCommonFindings[field] = this.selectedCommonFindings[
            field
          ].filter((x) => x !== this.normalFindingsId);
      }
      if (
        this.selectedCommonFindings.left.length == 1 &&
        this.selectedCommonFindings.left.includes(this.normalFindingsId) &&
        this.selectedCommonFindings.right.length == 1 &&
        this.selectedCommonFindings.right.includes(this.normalFindingsId)
      )
        this.findingsMaster.isNormalOUChecked = true;
      else this.findingsMaster.isNormalOUChecked = false;
      let res = tempValue.concat(
        this.commonFindings.list
          .filter((x) =>
            this.selectedCommonFindings[field].includes(x.findingsID)
          )
          .map((x) => ({
            ...x,
            field,
            type: "common",
          }))
      );
      if (!(isChecked && option.value === this.normalFindingsId))
        res = res.concat(tempAdditional);
      this.$emit("input", res);

      if (this.masterName == "FundusPhoto") {
        this.isEvaluated.fundusPhoto = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "ExternalPhoto") {
        this.isEvaluated.externalPhoto = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "AnteriorSegmentOCT") {
        this.isEvaluated.anteriorSegmentOCT = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "OpticNerveOCT") {
        this.isEvaluated.opticNerveOCT = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "MacularOCT") {
        this.isEvaluated.macularOCT = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "CornealTopography") {
        this.isEvaluated.cornealTopography = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "LensStatus") {
        this.isEvaluated.lensStatus = true;
        this.navigationBlocker.val = false;
      }
    },
    handleAdditionalFindingsChange(field) {
      let tempValue = [...this.value];
      let tempCommon = tempValue.filter(
        (x) =>
          x.findingsMasterID == this.findingsMaster.masterID &&
          x.field == field &&
          x.type == "common"
      );
      tempValue = tempValue.filter(
        (x) =>
          x.findingsMasterID != this.findingsMaster.masterID || x.field != field
      );

      if (
        this.selectedAdditionalFindings.left.length > 0 ||
        this.selectedAdditionalFindings.right.length > 0
      )
        this.findingsMaster.isNormalOUChecked = false;
      else this.findingsMaster.isNormalOUChecked = false;

      let additionalFindings = [];
      for (let option of this.additionalFindings.list)
        for (let findings of option.findings)
          if (
            this.selectedAdditionalFindings[field].includes(findings.findingsID)
          )
            additionalFindings.push(findings);
      let res = [];
      if (this.selectedCommonFindings[field].includes(this.normalFindingsId))
        this.selectedCommonFindings[field] = [];
      else res = tempCommon;
      this.$emit(
        "input",
        tempValue.concat(res).concat(
          additionalFindings.map((x) => ({
            ...x,
            field,
            type: "additional",
          }))
        )
      );
      if (this.masterName == "FundusPhoto") {
        this.isEvaluated.fundusPhoto = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "Externalphoto") {
        this.isEvaluated.externalPhoto = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "AnteriorSegmentOCT") {
        this.isEvaluated.anteriorSegmentOCT = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "OpticNerveOCT") {
        this.isEvaluated.opticNerveOCT = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "MacularOCT") {
        this.isEvaluated.macularOCT = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "CornealTopography") {
        this.isEvaluated.cornealTopography = true;
        this.navigationBlocker.val = false;
      } else if (this.masterName == "LensStatus") {
        this.isEvaluated.lensStatus = true;
        this.navigationBlocker.val = false;
      }
    },
    handleFindingsReset() {
      this.resetSelectedFindings();
      this.$emit(
        "input",
        this.value.filter(
          (x) => x.findingsMasterID != this.findingsMaster.masterID
        )
      );
    },
    handleFindingsNormalOU() {
      this.setToNormalOU();
      let tempValue = this.value.filter(
        (x) => x.findingsMasterID != this.findingsMaster.masterID
      );
      let NormalFindingsObj = this.commonFindings.list.find(
        (x) => x.findingsID == this.normalFindingsId
      );
      tempValue.push({ ...NormalFindingsObj, field: "left", type: "common" });
      tempValue.push({ ...NormalFindingsObj, field: "right", type: "common" });
      this.$emit("input", tempValue);
    },
    handleCancel() {
      this.findingsMaster.showManageFindings = false;
    },
    isMyFindings(createdBy) {
      return (
        createdBy == sessionStorage.getItem("UserId") || this.isSuperAdmin()
      );
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
  provide() {
    return {
      handleCommonFindingsChange: this.handleCommonFindingsChange,
      handleManageFindingsClick: this.handleManageFindingsClick,
      handleDeleteFindingsClick: this.handleDeleteFindingsClick,
      handleFindingsReset: this.handleFindingsReset,
      handleAdditionalFindingsChange: this.handleAdditionalFindingsChange,
      handleCancel: this.handleCancel,
      handleFindingsNormalOU: this.handleFindingsNormalOU,
      handleload: this.loadFindings,
      isMyFindings: this.isMyFindings,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default findingsHandler;

export const withfindingsHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<findingsHandler><WrappedComponent v-bind="$props"></WrappedComponent></findingsHandler>`,
    components: { findingsHandler, WrappedComponent },
  };
};
