<template>
  <div class="md-layout">
    <findingsHandler
      :masterName="masterName"
      :page="page"
      v-model="findings.selectedFindings"
      :savedFindings="findings.savedFindings"
      :screeningId="manageScreening.screeningId"
    >
      <lens-findings />
    </findingsHandler>
  </div>
</template>

<script>
import findingsHandler from "@/handlers/module-handlers/findings/findingsHandler.js";
import LensFindings from "./findings/lens-findings.vue";

export default {
  data() {
    return {
      masterName: "LensStatus",
      page: "screeningeval",
    };
  },
  components: {
    findingsHandler,
    LensFindings,
  },
  inject: ["findings", "manageScreening"],
};
</script>
