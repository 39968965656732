<template>
  <div>
    <div class="button-container" style="align center">
      <md-button
        v-show="!isEvaluated.isReadOnly"
        class="md-primary"
        @click="handleManageFindingsClick('Additional')"
      >
        Add Additional Findings</md-button
      >
      <md-button
        v-show="!isEvaluated.isReadOnly"
        class="md-danger-outline"
        @click="handleFindingsReset()"
      >
        Reset</md-button
      >
      <md-button
        v-if="!isEvaluated.isReadOnly"
        :class="findingsMaster.isNormalOUChecked ? 'md-green' : 'md-primary'"
        name="normalOU"
        v-bind:value="true"
        @click="normalOuChecked()"
        :disabled="isEvaluated.isReadOnly"
        >Normal OU</md-button
      >
    </div>
    <md-dialog :md-active.sync="findingsMaster.showManageFindings">
      <ManageFindingsHandler
        :type="findingsMaster.manageFindingsType"
        :findingsId="findingsMaster.manageFindingsId"
      >
        <ManageFindings></ManageFindings>
      </ManageFindingsHandler>
    </md-dialog>
  </div>
</template>

<script>
import ManageFindingsHandler from "@/handlers/module-handlers/findings/ManageFindingsHandler";
import ManageFindings from "@/components/Globechek/Findings/manage-findings/ManageFindings.vue";

export default {
  components: {
    ManageFindingsHandler,
    ManageFindings,
  },
  inject: [
    "handleManageFindingsClick",
    "selectedCommonFindings",
    "findingsMaster",
    "handleFindingsReset",
    "handleFindingsNormalOU",
    "isEvaluated",
  ],
  methods: {
    normalOuChecked() {
      if (this.findingsMaster.isNormalOUChecked) {
        this.handleFindingsReset();
      } else this.handleFindingsNormalOU();
    },
  },
};
</script>

<style scoped>
.radioLeft {
  display: block;
  text-align: left;
}
.md-radio {
  margin: 0 15px 10px 0;
  padding: 5px 0px 0 10px;
}
.radio-button {
  border: 1px solid #4e4646;
  padding: 5px 0px 0 10px;
  border-radius: 25px;
  width: 134px;
  height: 35px;
  vertical-align: baseline;
  align-items: center;
}
.radio-button:hover {
  background: rgb(216, 214, 214);

  color: rgb(0, 0, 0);
}
</style>
