<template>
  <div class="md-layout-item md-size-100">
    <globe-activity-slim-card :hasHeader="hasHeader">
      <template v-slot:header>
        <list-card-header :title="title">
          <template v-slot:title>
            <slot name="card-title"></slot>
          </template>
          <template v-slot:actions>
            <slot name="card-actions"></slot>
          </template>
        </list-card-header>
      </template>
      <template v-slot:content>
        <div class="md-layout">
          <div class="md-layout-item" align="Left">
            <list-header-layout :isReadOnly="isReadonly">
              <template v-slot:actions>
                <slot name="header-actions"></slot>
              </template>
            </list-header-layout>
            <div
              v-if="
                list.pages[listInput.page] &&
                list.pages[listInput.page].isLoading
              "
              class="globe-activity md-layout-item md-size-100 text-center"
            >
              <spinner :diameter="30"></spinner>
            </div>
            <div
              v-else-if="
                list.pages[listInput.page] &&
                list.pages[listInput.page].items &&
                list.pages[listInput.page].items.length == 0
              "
            >
              No Results Available
            </div>
            <div v-else>
              <slot name="content"></slot>
              <list-footer-layout></list-footer-layout>
            </div>
          </div>
        </div>
      </template>
    </globe-activity-slim-card>
  </div>
</template>

<script>
import ListCardHeader from "./ListCardHeader.vue";
import GlobeActivitySlimCard from "@/components/Globechek/Cards/GlobeActivitySlimCard.vue";
import ListFooterLayout from "./ListFooterLayout.vue";
import ListHeaderLayout from "./ListHeaderLayout.vue";
export default {
  inject: ["list", "listInput"],
  components: {
    ListCardHeader,
    GlobeActivitySlimCard,
    ListFooterLayout,
    ListHeaderLayout,
  },
  props: {
    headerColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>
