<template>
  <div>
    <md-table
      :value="
        list.pages[listInput.page] && list.pages[listInput.page].items
          ? list.pages[listInput.page].items
          : []
      "
      class="
        table-shopping table-striped
        globe-activity
        md-table-global-sales
        RefDoctor
        responsive
      "
    >
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Select">
          <input
            type="checkbox"
            :value="item.personEntryID"
            v-model="item.isSelected"
            @click="handleClick(item)"
            :disabled="isEvaluated.isReadOnly"
          />
        </md-table-cell>
        <md-table-cell md-label="Name" @click.native="handleSelect(item)">{{
          item.name
        }}</md-table-cell>
        <md-table-cell md-label="Phone">{{ item.cellNumber }}</md-table-cell>
        <md-table-cell md-label="City">{{ item.city }}</md-table-cell>
        <md-table-cell md-label="State">{{ item.state }}</md-table-cell>
        <md-table-cell md-label="Zipcode">{{ item.zipcode }}</md-table-cell>
        <md-table-cell md-label="Address"
          >{{ item.address1 }} {{ item.address2 }} <br />
          <a :href="item.webSite" target="_blank">{{ item.webSite }}</a>
        </md-table-cell>
        <md-table-cell md-label="Specialization">{{
          item.specialization
        }}</md-table-cell>
      </md-table-row>
    </md-table>
    <md-dialog :md-active.sync="showDialog">
      <div class="modal-size-medium">
        <div class="modal-header">{{ details.name }}</div>
        <a class="cancel-profile" @click="cancel()">X</a>
        <div class="d-flex">
          <div class="modal-content scroll p-0 w-100">
            <div class="md-layout">
              <p class="md-layout-item md-size-50">
                <b>City:</b>
              </p>
              <p class="md-layout-item md-size-50">{{ details.city }}</p>
            </div>
            <div class="md-layout">
              <p class="md-layout-item md-size-50"><b>State:</b></p>
              <p class="md-layout-item md-size-50">{{ details.state }}</p>
            </div>
            <div class="md-layout">
              <p class="md-layout-item md-size-50">
                <b>Zip code:</b>
              </p>
              <p class="md-layout-item md-size-50">{{ details.zipcode }}</p>
            </div>
            <div class="md-layout">
              <p class="md-layout-item md-size-50">
                <b>Specialization:</b>
              </p>
              <p class="md-layout-item md-size-50">
                &nbsp;{{ details.specialization }}
              </p>
            </div>
            <div class="md-layout">
              <p class="md-layout-item md-size-50"><b>Phone:</b></p>
              <p class="md-layout-item md-size-50">{{ details.cellNumber }}</p>
            </div>
          </div>

          <div
            class="md-layout w-100"
            style="justify-content: space-around; margin-top: 5%"
          >
            <img
              class="search-user-img"
              src="@/assets/img/user_avatar.png"
              alt="referral doctor"
            />
          </div>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: "ReferralDoctorsTable",
  inject: [
    "listInput",
    "list",
    "handleDoctorClick",
    "referralDoctors",
    "handleReferralClick",
    "findingsMaster",
    "isEvaluated",
  ],
  props: {
    avatar: {
      type: String,
      default:
        "https://globecheckportal.azurewebsites.net/img/user_avatar.87b02a18.png", // "../../img/GlobeChek_EYE.png"
    },
  },
  methods: {
    handleClick(item) {
      //this.handleDoctorClick(item.personEntryID);
      this.handleReferralClick(item);
    },
    bindSavedDoctors() {
      if (
        this.list.pages[this.listInput.page] &&
        this.list.pages[this.listInput.page].items &&
        this.referralDoctors.savedList.length > 0
      )
        this.list.pages[this.listInput.page].items = this.list.pages[
          this.listInput.page
        ].items.map((x) => ({
          ...x,
          isSelected:
            this.referralDoctors.savedList.find(
              (y) => y.personEntryID == x.personEntryID
            ) != null,
        }));
    },
    handleSelect(item) {
      this.showDialog = true;
      this.details = item;
    },
    cancel() {
      this.showDialog = false;
    },
  },

  data() {
    return {
      showDialog: false,
      details: {},
      val: [],
    };
  },
  created() {
    this.bindSavedDoctors();
  },
  watch: {
    "referralDoctors.savedList"() {
      this.bindSavedDoctors();
    },
    "list.pages"() {
      this.bindSavedDoctors();
    },
  },
};
</script>

<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.cancel-profile {
  position: absolute;
  right: 12px;
  top: 12px;
  border: 1px solid grey;
  padding: 3px 8px;
  color: black;
  border-radius: 5px;
}
.search-user-img {
  max-width: 50%;
  border-radius: 50%;
  border: 1px solid blue;
  height: fit-content;
}
.profile-dialog {
  padding: 15px;
}
.profile-val {
  font-weight: normal;
  text-transform: capitalize;
}
.md-list-item-content {
  font-weight: bolder !important;
}
.md-table-cell {
  //padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.RefDoctor.responsive td:nth-of-type(1):before {
  content: "Select";
}
.RefDoctor.responsive td:nth-of-type(2):before {
  content: "Name";
}
.RefDoctor.responsive td:nth-of-type(3):before {
  content: "Phone";
}
.RefDoctor.responsive td:nth-of-type(4):before {
  content: "City";
}
.RefDoctor.responsive td:nth-of-type(5):before {
  content: "State";
}
.RefDoctor.responsive td:nth-of-type(6):before {
  content: "Zipcode";
}
.RefDoctor.responsive td:nth-of-type(7):before {
  content: "Address";
}
.RefDoctor.responsive td:nth-of-type(8):before {
  content: "Specialization";
}
</style>
