<template>
  <div class="steper-content">
    <div id="diagnoses">
      <md-field>
        <md-textarea
          :readonly="isEvaluated.istechnotes"
          v-model="screeningNotes.value"
        ></md-textarea>
      </md-field>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  inject: ["screeningNotes", "Permissions", "isEvaluated"],

  computed: {
    ...mapState({
      userClaims: (state) => state.profile.userClaims,
    }),
  },
};
</script>

<style></style>
