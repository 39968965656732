<!-- Evaluation COMMENTS component -->
<template>
  <div>
    <div class="scroll p-2 overflow-y-auto">
      <!-- PDF REPORT (START) -->
      <div id="pdf" style="margin: 5px">
        <article id="heading">
          <section style="visibility: hidden">
            <span>GlobeChek, LLC</span> <br />
            <span>1-877-GlobeChek</span> <br />
            <span>www.GlobeChek.com</span>
          </section>
          <section class="gclogo">
            <img
              id="gclogo"
              alt="GlobeChek"
              src="@/assets/img/GlobeChek.jpg"
              @error="handleImageError"
              class="img-responsive"
              style="width: 150px"
            />
          </section>
          <section>
            <span>GlobeChek, LLC</span> <br />
            <span>1-877-GlobeChek</span> <br />
            <span>www.GlobeChek.com</span>
          </section>
        </article>

        <br />

        <article id="member">
          <section>
            <dl id="info-screen">
              <dt>Name</dt>
              <dd>
                {{ patientinfo.value.name }}
                <!-- eval.patientinfo.firstName&nbsp; eval.patientinfo.lastName -->
              </dd>
              <dt>Scan ID</dt>
              <dd>
                {{ screeninginfo.value.deviceName }} -
                {{ this.$route.params.id.substring(0, 10).toUpperCase() }}
              </dd>
              <!-- - screeningID.slice(0, 8) -->

              <dt>Scan Date</dt>
              <dd>{{ this.getConvertedDate() }}</dd>
              <dt>DOB (age)</dt>
              <dd>{{ patientinfo.value.dob }} ({{ patientinfo.value.age }})</dd>
              <dt>Email</dt>
              <dd>{{ patientinfo.value.email }}</dd>
              <dt>Cell Number</dt>
              <dd>{{ patientinfo.value.phone }}</dd>
            </dl>
            <dl id="info-member"></dl>
          </section>

          <section
            class="hg"
            style="display: flex; justify-content: flex-end; text-align: right"
          >
            <dl id="info-address">
              <dt>Address</dt>
              <br />
              <dd>
                {{ patientinfo.value.desktopaddress1 }}
              </dd>
              <br v-if="patientinfo.value.desktopaddress2" />
              <dd>{{ patientinfo.value.desktopaddress2 }}</dd>
              <br />
              <dd>
                {{ patientinfo.value.city + "," }} {{ patientinfo.value.state }}
                {{ patientinfo.value.zipcode }}
              </dd>
              <dd>{{ patientinfo.value.zip }} &nbsp;</dd>
            </dl>
          </section>
        </article>
        <hr style="margin: 0px" />

        <article id="screening">
          <section>
            <dl id="screening-title">
              <dt>&nbsp;</dt>
              <dt class="mtitle">Vision:</dt>
              <dt style="font-size: 0.75rem">&nbsp;</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dt class="mtitle">Color / Stereo:</dt>
              <dt style="font-size: 0.75rem">&nbsp;</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dt class="mtitle">Wavefront Auto Refraction:</dt>
              <dt class="spacer title">&nbsp;</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dt class="mtitle">Anterior Segment OCT:</dt>
              <dt class="spacer">&nbsp;</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dt class="mtitle">Intra Ocular Pressure:</dt>
              <dt>&nbsp;</dt>
            </dl>
          </section>

          <section>
            <dl id="screening-subtitle">
              <dt>&nbsp;</dt>
              <dt class="rl-top">Right Eye:</dt>
              <dt class="spacer rl-top">Left Eye:</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dt class="rl-top">Right Eye:</dt>
              <dt class="spacer rl-top">Left Eye:</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dt class="rl-top">Right Eye:</dt>
              <dt class="spacer rl-top">Left Eye:</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dt class="rl-top">Right Eye:</dt>
              <dt class="spacer rl-top">Left Eye:</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dt class="rl-top">Right Eye:</dt>
              <dt class="rl-top">Left Eye:</dt>
            </dl>
          </section>

          <section>
            <dl id="screening-col1">
              <dt class="heading">Distance VA</dt>
              (
              {{
                screeninginfo.value.screeningVision
                  .vision_acuity_with_correctionD == "n/a" ||
                screeninginfo.value.screeningVision
                  .vision_acuity_with_correctionD == null
                  ? "sc"
                  : "cc"
              }})
              <dd v-if="screeningVision.value.va_od_dist != ''">
                {{ screeningVision.value.va_od_dist }}
              </dd>
              <dd v-else>n/a</dd>
              <dd class="spacer" v-if="screeningVision.value.va_os_dist != ''">
                {{ screeningVision.value.va_os_dist }}
              </dd>
              <dd v-else>n/a</dd>
              <!--SPACER-->
              <dt class="heading">Color</dt>
              <dd v-if="screeningVision.value.va_od_color !== ''">
                {{ screeningVision.value.va_od_color }}
              </dd>
              <dd v-else>n/a</dd>

              <dd
                class="spacer"
                v-if="screeningVision.value.va_os_color !== ''"
              >
                {{ screeningVision.value.va_os_color }}
              </dd>
              <dd class="spacer" v-else>n/a</dd>
              <!--SPACER-->
              <dt class="heading">Sphere</dt>
              <dd>
                {{
                  screeningVision.value.ref_od_sph !== ""
                    ? screeningVision.value.ref_od_sph
                    : "n/a"
                }}
              </dd>
              <dd class="spacer">
                {{
                  screeningVision.value.ref_os_sph !== ""
                    ? screeningVision.value.ref_os_sph
                    : "n/a"
                }}
              </dd>
              <!--SPACER-->
              <dt class="heading">Angle</dt>
              <!-- <dd v-html="OD_Angle" /> -->
              <dd v-if="screeningVision.value.ant_od_ang != ''">
                {{ screeningVision.value.ant_od_ang }}
              </dd>

              <dd v-else>n/a</dd>

              <!-- <dd v-html="OS_Angle" /> -->
              <dd class="spacer" v-if="screeningVision.value.ant_os_ang != ''">
                {{ screeningVision.value.ant_os_ang }}
              </dd>
              <dd v-else>n/a</dd>
              <!--SPACER-->
              <dt class="heading">Eye Pressure</dt>
              <dd v-if="screeningVision.value.iop_od_eyepres != ''">
                {{ screeningVision.value.iop_od_eyepres }}
              </dd>
              <dd v-else>n/a</dd>

              <dd
                class="spacer"
                v-if="screeningVision.value.iop_os_eyepres != ''"
              >
                {{ screeningVision.value.iop_os_eyepres }}
              </dd>
              <dd class="spacer" v-else>n/a</dd>
            </dl>
          </section>

          <section>
            <dl id="screening-col2">
              <dt class="heading">Near VA</dt>
              (
              {{
                screeninginfo.value.screeningVision
                  .vision_acuity_with_correctionN == "n/a" ||
                screeninginfo.value.screeningVision
                  .vision_acuity_with_correctionN == null
                  ? "sc"
                  : "cc"
              }}
              )
              <dd v-if="screeningVision.value.va_od_near != ''">
                {{ screeningVision.value.va_od_near }}
              </dd>
              <dd v-else>n/a</dd>
              <dd class="spacer"></dd>
              <dd v-if="screeningVision.value.va_os_near != ''">
                {{ screeningVision.value.va_os_near }}
              </dd>
              <dd v-else>n/a</dd>
              <!--SPACER-->
              <dt class="heading">Depth (Binocular)</dt>
              <dd v-if="screeningVision.value.va_od_stereo !== ''">
                {{ screeningVision.value.va_od_stereo }}
              </dd>
              <dd v-else>n/a</dd>
              <dd
                v-if="screeningVision.value.va_os_stereo !== ''"
                class="spacer"
              >
                {{ screeningVision.value.va_os_stereo }}
              </dd>
              <dd class="spacer" v-else>n/a</dd>
              <!--SPACER-->
              <dt class="heading">Cylinder</dt>
              <dd v-if="screeningVision.value.ref_od_cyl != ''">
                {{ screeningVision.value.ref_od_cyl }}
              </dd>
              <dd class="spacer" v-else>n/a</dd>
              <dd class="spacer" v-if="screeningVision.value.ref_os_cyl != ''">
                {{ screeningVision.value.ref_os_cyl }}
              </dd>

              <dd class="spacer" v-else>n/a</dd>
              <!--SPACER-->

              <dt class="heading">Thickness</dt>
              <dd v-if="screeningVision.value.ant_od_thick != ''">
                {{ screeningVision.value.ant_od_thick }}
              </dd>
              <dd v-else>n/a</dd>

              <dd v-if="screeningVision.value.ant_os_thick != ''">
                {{ screeningVision.value.ant_os_thick }}
              </dd>
              <dd v-else>n/a</dd>

              <!-- <dd
                class="spacer"
                v-if="screeningVision.value.iop_os_correct !== null"
              >
                {{ screeningVision.value.iop_os_correct }}
              </dd> -->
              <!-- <dd class="spacer" v-else>n/a</dd> -->
              <!--SPACER-->
              <dt class="heading">Corrected IOP</dt>
              <dd v-if="screeningVision.value.iop_od_correct != ''">
                {{ screeningVision.value.iop_od_correct }}
              </dd>
              <dd v-else>n/a</dd>

              <dd
                class="spacer"
                v-if="screeningVision.value.iop_os_correct != ''"
              >
                {{ screeningVision.value.iop_os_correct }}
              </dd>
              <dd class="spacer" v-else>n/a</dd>
            </dl>
          </section>

          <section>
            <dl id="screening-col3">
              <dt>&nbsp;</dt>
              <dd>&nbsp;</dd>
              <dd class="spacer">&nbsp;</dd>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dd>&nbsp;</dd>
              <dd class="spacer">&nbsp;</dd>
              <!--SPACER-->
              <dt class="heading">Axis</dt>
              <dd>
                {{
                  screeningVision.value.ref_od_axis !== ""
                    ? screeningVision.value.ref_od_axis
                    : "n/a"
                }}
              </dd>
              <dd class="spacer">
                {{
                  screeningVision.value.ref_os_axis !== ""
                    ? screeningVision.value.ref_os_axis
                    : "n/a"
                }}
              </dd>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dd>&nbsp;</dd>
              <dd class="spacer">&nbsp;</dd>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dd>&nbsp;</dd>
              <dd>&nbsp;</dd>
            </dl>
          </section>

          <section>
            <dl id="screening-col4">
              <dt>&nbsp;</dt>
              <dd>&nbsp;</dd>
              <dd>&nbsp;</dd>
              <dt class="spacer">&nbsp;</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dd>
                <i><!--* Not valid for eye glasses--></i>
              </dd>
              <dd>
                <i><!--prescription--></i>
              </dd>
              <dt class="spacer">&nbsp;</dt>
              <!--SPACER-->
              <dt>&nbsp;</dt>
              <dd>&nbsp;</dd>
              <dd>&nbsp;</dd>
              <dt class="spacer">&nbsp;</dt>
              <!--SPACER-->
            </dl>
          </section>
        </article>
        <hr style="margin-top: 10px; margin-bottom: 5px" />

        <!--EXTERNAL & CORNEAL TOPOGRAPHY PHOTO-->
        <table class="report" style="width: 100%">
          <tbody>
            <tr align="right" style="line-height: 10px">
              <td class="label1" align="left" colspan="2">External Photo:</td>
              <td class="label1" align="left" colspan="2">
                Wavefront Corneal Topography:
              </td>
            </tr>
            <tr align="right" style="line-height: 12px">
              <td align="left" colspan="2">
                <p class="field-rl">&nbsp;</p>
                <div
                  v-for="image in externalphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <img
                    :src="image.imgLink.href"
                    @error="handleImageError"
                    class="img-responsive"
                    alt="External Photo Image"
                    style="width: 250px"
                  />
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" (eval.externalPhoto.findingsOD.length === 0 &&
                          eval.externalPhoto.findingsOS.length === 0) || (eval.externalPhoto.findingsOD[0]
                          === 'Normal' && eval.externalPhoto.findingsOS[0] === 'Normal') " -->
                  {{ this.getLableFindings("ExternalPhoto", "right", "OD") }}
                </div>
                <div class="label2" style="color: black">
                  {{ this.getLableFindings("ExternalPhoto", "left", "OS") }}
                </div>
                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.externalPhoto.findingsOD[0] != 'Normal'">
                          <span><b>OD</b>: </span>
                          <span style="color: red">
                            {{ eval.externalPhoto.findingsOD.join(", ") }}
                          </span>
                        </div>
                        <br />
                        <div v-if="eval.externalPhoto.findingsOS[0] != 'Normal'">
                          <span><b>OS</b>: </span>
                          <span style="color: red">
                            {{ eval.externalPhoto.findingsOS.join(", ") }}
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>

              <td align="left">
                <p class="field-rl">OD</p>
                <div
                  v-for="image in corneaODphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <div v-if="image.eyeSection == 'od'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Corneal Topography Right"
                      style="width: 190px"
                    />
                  </div>
                </div>
                <!-- <img
                        src="@/assets/img/GlobeChek.jpg"
                        class="img-responsive"
                        alt="Corneal Topography Right"
                        style="width: 190px"
                      /> -->
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.corneaPhoto.findingsOD.length === 0 || -->
                  <!-- eval.corneaPhoto.findingsOD[0] === 'Normal' " -->
                  {{
                    this.getLableFindings("CornealTopography", "right", "OD")
                  }}
                </div>
                <!-- <div v-else class="imgdiag">
                        <div  v-if="eval.corneaPhoto.findingsOD.length > 0">
                          <span><b>OD</b>: </span>
                          <span style="color: red">
                            {{ eval.corneaPhoto.findingsOD.join(", ") }}
                          </span>
                        </div>
                      </div> -->
              </td>
              <td align="left">
                <p class="field-rl">OS</p>
                <div
                  v-for="image in corneaOSphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <div v-if="image.eyeSection == 'os'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Corneal Topography LEFT"
                      style="width: 190px"
                    />
                  </div>
                </div>
                <!-- <img
                        src="@/assets/img/GlobeChek.jpg"
                        class="img-responsive"
                        alt="Corneal Topography LEFT"
                        style="width: 190px"
                      /> -->
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.corneaPhoto.findingsOS.length === 0 ||
                        eval.corneaPhoto.findingsOS[0] === 'Normal' " -->
                  {{ this.getLableFindings("CornealTopography", "left", "OS") }}
                </div>
                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.corneaPhoto.findingsOS.length > 0">
                          <span><b>OS</b>: </span>
                          <span style="color: red">
                            {{ eval.corneaPhoto.findingsOS.join(", ") }}
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
            </tr>
          </tbody>
        </table>
        <hr style="margin-top: 10px; margin-bottom: 3px" />

        <!--PACHYMETRY-->
        <table class="report" style="width: 100%">
          <tbody>
            <tr align="right" style="line-height: 12px">
              <td class="label1" align="left">
                Anterior Segment OCT and Pachymetry:
              </td>
              <td class="label1" align="left">&nbsp;</td>
            </tr>
            <tr align="right" style="line-height: 12px">
              <td align="center">
                <p class="field-rl">OD</p>
                <div
                  v-for="image in AnteriorODphoto.value.slice(0, 1)"
                  :key="image.id"
                >
                  <div v-if="image.eyeSection == 'od'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Pachymetry Image OD"
                      style="width: 320px"
                    />
                  </div>
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.pachymetryPhoto.findingsOD.length === 0 ||
                        eval.pachymetryPhoto.findingsOD[0] === 'Normal' " -->

                  {{
                    this.getLableFindings("AnteriorSegmentOCT", "right", "OD")
                  }}
                </div>

                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.pachymetryPhoto.findingsOD.length > 0">
                          <span><b>OD</b>: </span>
                          <span style="color: red">
                            eval.pachymetryPhoto.findingsOD.join(", ")
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
              <td align="center">
                <p class="field-rl">OS</p>
                <div
                  v-for="image in AnteriorOSphoto.value.slice(0, 1)"
                  :key="image.id"
                >
                  <div v-if="image.eyeSection == 'os'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      name="paceholder"
                      class="img-responsive"
                      alt="Pachymetry Image OS"
                      style="width: 320px"
                    />
                  </div>
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.pachymetryPhoto.findingsOS.length === 0 ||
                        eval.pachymetryPhoto.findingsOS[0] === 'Normal' " -->

                  {{
                    this.getLableFindings("AnteriorSegmentOCT", "left", "OS")
                  }}
                </div>
                <!-- <div class="imgdiag" v-else>
                        <div v-if="eval.pachymetryPhoto.findingsOS.length > 0">
                          <span><b>OS</b>: </span>
                          <span style="color: red">
                            eval.pachymetryPhoto.findingsOS.join(", ")
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <hr style="margin-top: 0px; margin-bottom: 3px" />
        <!--OPACITY-->
        <table class="report" style="width: 100%">
          <tbody>
            <tr align="right" style="line-height: 12px">
              <td class="label1" align="left">
                Lens Opacity / Cataract Grading:
              </td>
              <td class="label1" align="left">&nbsp;</td>
            </tr>
            <tr align="right" style="line-height: 12px">
              <td align="center">
                <p class="field-rl">OD</p>
                <div
                  v-for="image in lensStatus.value.slice(0, 1)"
                  :key="image.index"
                >
                  <img
                    :src="image.imgLink.href"
                    @error="handleImageError"
                    class="img-responsive"
                    alt="Lens Opacity Image OD"
                    style="width: 300px"
                  />
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.opacityPhoto.findingsOD.length === 0 ||
                        eval.opacityPhoto.findingsOD[0] === 'Normal' " -->
                  {{ this.getLableFindings("LensStatus", "right", "OD") }}
                </div>
                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.opacityPhoto.findingsOD.length > 0">
                          <span><b>OD</b>: </span>
                          <span style="color: red">
                            {{eval.opacityPhoto.findingsOD.join(", ")}}
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
              <td align="center">
                <p class="field-rl">OS</p>
                <div
                  v-for="image in lensStatus.value.slice(0, 1)"
                  :key="image.index"
                >
                  <img
                    :src="image.imgLink.href"
                    @error="handleImageError"
                    class="img-responsive"
                    alt="Lens Opacity Image OD"
                    style="width: 300px"
                  />
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.opacityPhoto.findingsOS.length === 0 ||
                          eval.opacityPhoto.findingsOS[0] === 'Normal' " -->
                  {{ this.getLableFindings("LensStatus", "left", "OS") }}
                </div>

                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.opacityPhoto.findingsOS.length > 0">
                          <span><b>OS</b>: </span>
                          <span style="color: red">
                            eval.opacityPhoto.findingsOS.join(", ")
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
            </tr>
          </tbody>
        </table>

        <hr style="margin-top: 7px; margin-bottom: 3px" />

        <!--FUNDUS-->
        <table class="report" style="width: 100%">
          <tbody>
            <tr align="right" style="line-height: 12px">
              <td class="label1" align="left">
                Retina and Optic Nerve Photos:
              </td>
              <td class="label1" align="left">&nbsp;</td>
            </tr>
            <tr align="right" style="line-height: 12px">
              <td align="center">
                <p class="field-rl">OD</p>
                <div
                  v-for="image in FundusODphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <div v-if="image.eyeSection == 'od'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Fundus Photo OD"
                      style="width: 250px"
                    />
                  </div>
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.fundusPhoto.findingsOD.length === 0 ||
                        eval.fundusPhoto.findingsOD[0] === 'Normal' " -->
                  {{ this.getLableFindings("FundusPhoto", "right", "OD") }}
                </div>

                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.fundusPhoto.findingsOD.length > 0">
                          <span><b>OD</b>:</span>
                          <span style="color: red">
                            {{eval.fundusPhoto.findingsOD.join(", ")}}
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
              <td align="center">
                <p class="field-rl">OS</p>
                <div
                  v-for="image in FundusOSphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <div v-if="image.eyeSection == 'os'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Fundus Photo OS"
                      style="width: 250px"
                    />
                  </div>
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.fundusPhoto.findingsOS.length === 0 ||
                        eval.fundusPhoto.findingsOS[0] === 'Normal' " -->
                  {{ this.getLableFindings("FundusPhoto", "left", "OS") }}
                </div>

                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.fundusPhoto.findingsOS.length > 0">
                          <span><b>OS</b>: </span>
                          <span style="color: red">
                            {{eval.fundusPhoto.findingsOS.join(", ")}}
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
            </tr>
          </tbody>
        </table>

        <hr style="margin-top: 5px; margin-bottom: 5px" />

        <!--MACULA & NERVE OCT-->
        <table class="report" style="width: 100%">
          <tbody>
            <tr align="right" style="line-height: 12px">
              <td class="label1" align="left" colspan="2">Macular (OCT):</td>
              <td class="label1" align="left" colspan="2">
                Optic Nerve (OCT):
              </td>
            </tr>
            <tr align="right" style="line-height: 12px">
              <td align="center">
                <p class="field-rl">OD</p>
                <div
                  v-for="image in MacularODphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <div v-if="image.eyeSection == 'od'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Macual OCT OD"
                      style="width: 175px"
                    />
                  </div>
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.maculaPhoto.findingsOD.length === 0 ||
                        eval.maculaPhoto.findingsOD[0] === 'Normal' " -->
                  {{ this.getLableFindings("MacularOCT", "right", "OD") }}
                </div>

                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.maculaPhoto.findingsOD.length > 0">
                          <span><b>OD</b>:</span>
                          <span style="color: red">
                            {{eval.maculaPhoto.findingsOD.join(", ")}}
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
              <td align="center">
                <p class="field-rl">OS</p>
                <div
                  v-for="image in MacularOSphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <div v-if="image.eyeSection == 'os'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Macual OCT OS"
                      style="width: 175px"
                    />
                  </div>
                </div>
                <div class="label2" style="color: black">
                  <!-- v-if=" eval.maculaPhoto.findingsOS.length === 0 ||
                        eval.maculaPhoto.findingsOS[0] === 'Normal' " -->

                  {{ this.getLableFindings("MacularOCT", "left", "OS") }}
                </div>
                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.maculaPhoto.findingsOS.length > 0">
                          <span><b>OS</b>: </span>
                          <span style="color: red">
                            eval.maculaPhoto.findingsOS.join(", ")
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>

              <td align="center">
                <p class="field-rl">OD</p>
                <div
                  v-for="image in NerveODphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <div v-if="image.eyeSection == 'od'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Optic Nerve OCT OD"
                      style="width: 175px"
                    />
                  </div>
                </div>

                <div class="label2" style="color: black">
                  <!-- v-if=" eval.nervePhoto.findingsOD.length === 0 || eval.nervePhoto.findingsOD[0]
                        === 'Normal' " -->
                  {{ this.getLableFindings("OpticNerveOCT", "right", "OD") }}
                </div>
                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.nervePhoto.findingsOD.length > 0">
                          <span><b>OD</b>: </span>
                          <span style="color: red">
                            eval.nervePhoto.findingsOD.join(", ")
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
              <td align="center">
                <p class="field-rl">OS</p>
                <div
                  v-for="image in NerveOSphoto.value.slice(0, 1)"
                  :key="image.index"
                >
                  <div v-if="image.eyeSection == 'os'">
                    <img
                      :src="image.imgLink.href"
                      @error="handleImageError"
                      class="img-responsive"
                      alt="Optic Nerve OCT OD"
                      style="width: 175px"
                    />
                  </div>
                </div>

                <div class="label2" style="color: black">
                  <!-- v-if=" eval.nervePhoto.findingsOS.length === 0 || eval.nervePhoto.findingsOS[0]
                        === 'Normal' " -->
                  {{ this.getLableFindings("OpticNerveOCT", "left", "OS") }}
                </div>
                <!-- <div v-else class="imgdiag">
                        <div v-if="eval.nervePhoto.findingsOS.length > 0" >
                          <span><b>OS</b>: </span>
                          <span style="color: red">
                            {{eval.nervePhoto.findingsOS.join(", ")}}
                          </span>
                        </div>
                        <br />
                      </div> -->
              </td>
            </tr>
          </tbody>
        </table>

        <hr style="margin-top: 25px" />
        <div class="row">
          <div
            style="text-align: Left; margin-bottom: 10px; font-weight: 500px"
          >
            <h5>Summary and Recommendations</h5>
          </div>
          <ol>
            <li class="rd2" align="Left">
              <b>Diagnosis based on above screening:</b>
              <br />
              <p>
                <span
                  class="rd3"
                  style="white-space: pre-wrap"
                  v-html="diagnosisComments.value"
                  >&nbsp;</span
                >
              </p>
            </li>

            <br />

            <li class="rd2" align="Left">
              <b>Recommended follow-up:</b>
              <br />
              <p>
                <span
                  class="rd3"
                  style="white-space: pre-wrap"
                  v-html="recommendationComments.value"
                  >&nbsp;</span
                >
              </p>
            </li>
            <br />

            <li class="rd2" align="Left">
              <b>Recommended Doctor:</b>
              <ul class="search-list">
                <li v-for="(item, index) in recommendedDoc" v-bind:key="index">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-75 d-flex flex-column">
                      <h3>{{ item.name }}</h3>
                      <p>
                        <span v-if="item.specialization">
                          {{ item.specialization }}</span
                        >
                      </p>
                      <p>
                        <span v-if="item.address1"> {{ item.address1 }} </span>
                      </p>
                      <p>
                        {{ item.city !== null ? item.city : "" }}
                        {{ item.state !== null ? item.state : "" }}
                        {{ item.zipcode !== null ? item.zipcode : "" }}
                      </p>
                      <p class="Phone" v-if="item.cellNumber">
                        <span> {{ item.cellNumber }} </span>
                      </p>
                      <!-- <p>
                        <span v-if="item.city">City: {{ item.city }}, </span>
                      </p>
                      <p>
                        <span v-if="item.state">State: {{ item.state }}, </span>
                      </p>
                      <p>
                        <span v-if="item.zipcode"
                          >Zipcode: {{ item.zipcode }},
                        </span>
                      </p> -->
                      <p>
                        <span v-if="item.webSite"> {{ item.webSite }}</span>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ol>
        </div>

        <hr style="margin: 0px" />

        <div style="margin-left: 30px; margin-right: 30px" align="Left">
          <!-- v-if="!isTeam()" -->

          <h4><b>GlobeChek &trade;</b>&nbsp; Disclaimer:</h4>
          <div style="margin-top: 5px" align="Left">
            While your screening results were interpreted by a board-certified
            ophthalmologist, a <i>GlobeChek</i>&trade; screen is <b>not</b> a
            substitute for an eye exam by a qualified eye doctor.
            <i>GlobeChek</i>&trade; recommends regular eye exams as directed by
            the <i>American Academy of Ophthalmology</i> as well as the
            <i>American Academy of Optometry</i>. Do not disregard professional
            medical advice or delay in seeking it because of this screen. If you
            believe you may have a medical emergency, call your doctor, visit an
            emergency care provider such as the emergency room of a nearby
            hospital, or call 911 immediately.
          </div>
          <h3 style="text-align: center">* * *</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   getSubmit,
//   getCancel,
// } from "../../../services/control-services/ConfirmationService";
import { mapState } from "vuex";
//import previewrpt from "../screeningeval/lens-status.vue";
import moment from "moment";

export default {
  name: "ReportPreview",
  components: {},
  inject: [
    "previewReport",
    "showpreviewClick",
    "patientinfo",
    "screeninginfo",
    "screeningVision",
    "externalphoto",
    "Corneaphoto",
    "Fundusphoto",
    "FundusODphoto",
    "FundusOSphoto",
    "lensStatus",
    "confirmation",
    "AnteriorSegment",
    "AnteriorOSphoto",
    "AnteriorODphoto",
    "findings",
    "corneaODphoto",
    "corneaOSphoto",
    "findingsMaster",
    "findings",
    "additionalFindings",
    "commonFindings",
    "selectedAdditionalFindings",
    "selectedCommonFindings",
    "getLableFindings",
    "isEvaluated",
    "selecteddiagnosis",
    "selectedrecommendation",
    "handleImageError",
    "prescriptionData",
    "MacularODphoto",
    "MacularOSphoto",
    "NerveODphoto",
    "NerveOSphoto",
    "diagnosisComments",
    "recommendationComments",
  ],
  watch: {
    "patientinfo.value.name"() {
      this.$refs.patientInfo.$children[0].$children[0].$children[0].$el.children[1].innerText =
        this.patientinfo.value.name;
    },
    patientinfo: function () {
      // console.log("patientinfo ", this.patientinfo);
    },
  },

  data() {
    return {
      status: "",
      branding: false,
      patientName: "Jarvis helics",
      referraldoctors: [],
      props: {
        searchText: "",
        page: 1,
        size: 10,
        filterText: "",
        doctorType: "Doctor",
      },
    };
  },
  computed: {
    ...mapState({
      recommendedDoc: (state) => state.screeningeval.ReferralDoctors,
    }),
    diagnosis: {
      get: function () {
        let diag = "";
        var groupSelected = {};
        for (let i in this.findings.selectedFindings) {
          let key = this.findings.selectedFindings[i].findingsID;

          groupSelected[key] = {
            findings: this.findings.selectedFindings[i],
            count:
              groupSelected[key] && groupSelected[key].count
                ? groupSelected[key].count + 1
                : 1,
          };
        }
        var selectedValue = Object.values(groupSelected);
        for (let i in selectedValue) {
          if (selectedValue[i].count > 1) selectedValue[i].side = "both";
          else selectedValue[i].side = selectedValue[i].findings.field;
        }
        var groupSaved = {};
        for (let i in this.findings.savedFindings) {
          let key = this.findings.savedFindings[i].findingsID;

          groupSaved[key] = {
            findings: this.findings.savedFindings[i],
            count:
              groupSaved[key] && groupSaved[key].count
                ? groupSaved[key].count + 1
                : 1,
          };
        }
        var savedValue = Object.values(groupSaved);
        for (let i in savedValue) {
          if (savedValue[i].count > 1) savedValue[i].side = "both";
          else savedValue[i].side = savedValue[i].findings.field;
        }

        // for (let x = 0; x < savedValue.length; x++) {
        //   var eyes = savedValue[x].side == "both" ? " eyes, " : " eye, ";
        //   diag +=
        //     savedValue[x].findings.diagnosys +
        //     " on the " +
        //     savedValue[x].side +
        //     eyes;
        // }
        for (let x = 0; x < selectedValue.length; x++) {
          var eye = selectedValue[x].side == "both" ? " OU, " : " eye, ";
          diag +=
            selectedValue[x].findings.diagnosys +
            " " +
            (selectedValue[x].side == "both" ? "" : selectedValue[x].side) +
            eye;
        }
        return diag;
      },
      set: function () {
        //pass
      },
    },
    recommendations: {
      get: function () {
        let rec = "";
        var groupSelected = {};
        for (let i in this.findings.selectedFindings) {
          let key = this.findings.selectedFindings[i].findingsID;

          groupSelected[key] = {
            findings: this.findings.selectedFindings[i],
            count:
              groupSelected[key] && groupSelected[key].count
                ? groupSelected[key].count + 1
                : 1,
          };
        }
        var selectedValue = Object.values(groupSelected);
        for (let i in selectedValue) {
          if (selectedValue[i].count > 1) selectedValue[i].side = "both";
          else selectedValue[i].side = selectedValue[i].findings.field;
        }
        var groupSaved = {};
        for (let i in this.findings.savedFindings) {
          let key = this.findings.savedFindings[i].findingsID;

          groupSaved[key] = {
            findings: this.findings.savedFindings[i],
            count:
              groupSaved[key] && groupSaved[key].count
                ? groupSaved[key].count + 1
                : 1,
          };
        }
        var savedValue = Object.values(groupSaved);
        for (let i in savedValue) {
          if (savedValue[i].count > 1) savedValue[i].side = "both";
          else savedValue[i].side = savedValue[i].findings.field;
        }
        // for (let x = 0; x < savedValue.length; x++) {
        //   var eyes = savedValue[x].side == "both" ? " eyes, " : " eye, ";
        //   rec +=
        //     savedValue[x].findings.recommendation +
        //     " on the " +
        //     savedValue[x].side +
        //     eyes;
        // }
        for (let x = 0; x < selectedValue.length; x++) {
          var eye = selectedValue[x].side == "both" ? " OU, " : " eye, ";
          rec +=
            selectedValue[x].findings.recommendation +
            " " +
            (selectedValue[x].side == "both" ? "" : selectedValue[x].side) +
            eye;
        }
        return rec;
      },
      set: function () {
        //pass
      },
    },
  },
  methods: {
    // ...mapActions("user", ["getDoctorUsers"]),
    // getReferral() {
    //   {
    //     this.getDoctorUsers(this.props).then((res) => {
    //       this.referraldoctors = { ...res };
    //     });
    //   }
    // },
    getConvertedDate() {
      return moment(this.screeninginfo.value.startDate).format(
        "dddd MMMM Do, YYYY"
      );
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    onGenerate() {
      // this.showpreviewClick(false);
      // this.confirmation.title = "Register this Report ?";
      // this.confirmation.description =
      //   "Notification will be sent to member to view results";
      // // this.confirmation.cancel = "NO, CANCEL";
      // // this.confirmation.accept = "YES, REGISTER IT";
      // this.confirmation.show = true;
      // this.submitSubscription = getSubmit().subscribe(() => {
      //   this.confirmation.show = false;
      //   this.submitSubscription.unsubscribe();
      //   //this.$router.push("/globe-chek/Reports");
      // });
      // this.cancelSubscription = getCancel().subscribe(() => {
      //   this.confirmation.show = false;
      //   this.cancelSubscription.unsubscribe();
      // });
    },
    onPrint() {
      //console.log("Printing report...");
      const top = `<!DOCTYPE html><html lang="en">  <head>    <link href="rptAssets/css/reportPDFNEW.css" rel="stylesheet" type="text/css"/></head><body>`;
      const bottom = `<br /></body></html>`;
      const reportHTML =
        top + document.querySelector("#pdf").innerHTML + bottom;
      const mdata = new File([reportHTML], "../rptFile.html", {
        type: "text/html",
      });
      const blob = new Blob([mdata], { type: "text/html" });
      //////////////////////////
      const testMode = true;
      /////////////////////////
      if (testMode) {
        const e = document.createEvent("MouseEvents"),
          a = document.createElement("a");
        a.download = "report.html";
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ["text/html", a.download, a.href].join(":");
        e.initEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
      }
      //  else {
      //     var vm = this; var pdfFunc = this.API_SERVICE + "/pdf-report/";
      //     var uri = process.env.VUE_APP_SCREENING_REPORTS + "/" + this.screeningID +
      // ".pdf"; const config1 = { headers: { "Content-Type": "application/json" } };
      // const url1 = this.API_SERVICE + "/report-html"; const data1 = { pkgId:
      // this.screeningID, reportHtml: reportHTML }; axios .post(url1, data1, config1)
      // .then(function(response) { const config2 = { headers: { "Content-Type":
      // "application/json", "content-length": "199998" } }; const url2 = vm.API_SERVICE
      // + "/pdf-report/" + vm.screeningID; const data2 = {}; const configPrereg = {
      // headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin":
      // "*" } }; axios.get(url2, data2, config2).then(function(response) { window.open(
      // uri, "_blank", "location=yes,height=1000,width=900,scrollbars=yes,status=yes" );
      // if (vm.viewonly == "True") { return; } let screeningID =
      // sessionStorage.getItem("screeningID"); if
      // (localStorage.getItem("ongoingScreenings")) { var ongoingScreenings =
      // JSON.parse( localStorage.getItem("ongoingScreenings") ); let filteredScreenings
      // = ongoingScreenings.filter( id => id != screeningID ); localStorage.setItem(
      // "ongoingScreenings", JSON.stringify(filteredScreenings) ); if
      // (localStorage.getItem(screeningID)) { localStorage.removeItem(screeningID); } }
      // vm.showSwal("message-and-cancel"); }); }) .catch(function(error) {
      // }); }
    },
  },
};
</script>

<style lang="css" scoped>
.exit-icon {
  position: sticky;
  top: 15px;
  right: 15px;
  float: right;
  cursor: pointer;
  z-index: 50;
}
html body article#heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.8rem;
  line-height: 0.95rem;
}
html body article#member {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.8rem;
  line-height: 1.2rem;
}
.md-card {
  margin: 0;
}
.gclogo {
  background-size: 140px 80px;
  width: 200px;
  height: 80px;
}
/* .gclogo img {
  display: none;
} */
.jaglogo {
  background-size: 896px 207px;
  width: 896px;
  height: 207px;
}
/* .jaglogo img {
  display: none;
} */
.crclogo {
  background-size: 140px 80px;
  width: 200px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}
/* .crclogo img {
  display: none;
} */
/* html {
  color: #14213d;
  font-family: Roboto;
  font-size: 0.8rem;
} */
/* html body {
  margin: 0;
} */
/* html h1 {
  color: #1e5de4;
  font-family: Roboto;
  font-size: 40pt;
  margin: 0;
} */
html hr {
  border: none;
  height: 0.5px;
  color: #555;
  background-color: #555;
}
html body article#heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.8rem;
  line-height: 0.95rem;
}
html body article#heading section span {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0 5px 0 0;
  padding: 0;
  text-align: left;
}
html body article#member {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.8rem;
  line-height: 1.2rem;
}
html body article#member section dl {
  text-align: left;
}
html body article#member section dl dt,
section dl dd {
  color: rgb(153, 159, 170);
  display: inline;
  margin: 1px 0px;
}
html body article#member section dl dt {
  color: rgb(38, 41, 46);
}
html body article#member section dl dt::before {
  content: "";
  display: block;
}
html body article#member section dl dt::after {
  content: ": ";
}
html body article#screening {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.9rem;
  line-height: 1rem;
}
html body article#screening section dl {
  text-align: left;
}
html body article#screening section dl dt,
section dl dd {
  color: rgb(17, 70, 150);
  display: inline;
  margin: 1px 0px;
}
html body article#screening section dl dt {
  color: rgb(0, 0, 0);
}
html body article#screening section dl dt::before,
html body article#screening section dl dd {
  content: "";
  display: block;
}
html body article#screening section dl dt::after {
  content: "";
}
html body article#screening section dl dt.subhead {
  font-size: 0.75rem;
  color: rgb(78, 78, 109);
}
html body article#screening section dl dt.title {
  font-size: 0.75rem;
  font-weight: 500;
}
html body article#screening section dl dt.heading {
  font-size: 0.75rem;
  font-weight: 400;
  text-decoration: underline;
}
.spacer {
  margin-bottom: 2px;
}
#imgExt {
  width: 20%;
  height: auto;
}
.rl-top {
  font-size: 0.73rem;
}
.label1 {
  font-size: 0.88rem;
  font-weight: 600;
}
.label2 {
  font-size: 0.75rem;
  font-weight: 550;
  padding-top: 5px;
}
.field-norm {
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 6px;
  color: blue;
}
.field-rl {
  font-size: 0.75rem;
  margin: 0px 0px 5px 0px;
}
.field-top {
  font-size: 0.7rem;
  padding: 0px;
}
.mtitle {
  font-weight: 500;
  font-family: Roboto;
}
.imgdiag {
  line-height: 0.75em;
  font-size: 0.72rem;
  padding: 0px;
  margin-top: 10px;
}
.search-list li {
  border: 0px solid #ccc;
}
.hg {
  padding-right: 0px;
  width: 150px;
  height: 150px;
  position: relative;
}
</style>
