<!-- RX-PRESCRIPTION Info component -->
<template>
  <div class="steper-content">
    <div id="prescrioption">
      <h4 class="title">OD</h4>
      <div class="form-layout-horizondal">
        <div class="drop-down">
          <label>Sphere</label>
          <select-input
            name="Sphere"
            displayName="Sphere"
            :options="
              spherePowerList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.sphereOD"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
        <div class="drop-down">
          <label>Cylinder</label>
          <select-input
            displayName="Cylinder"
            :options="
              cylindricalPowerList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.cylinderOD"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
        <div class="drop-down">
          <label>Axis</label>
          <select-input
            displayName="Axis"
            :options="
              axisPowerList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.axisOD"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
        <div class="drop-down">
          <label>Add Power</label>
          <select-input
            displayName="Add Power"
            :options="
              additionalPowerList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.powerOD"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
        <div class="drop-down">
          <label>Distances</label>
          <select-input
            displayName="Distances"
            :options="
              distancesList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.distanceOD"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
      </div>
      <h4 class="title">OS</h4>
      <div class="form-layout-horizondal">
        <div class="drop-down">
          <label>Sphere</label>
          <select-input
            displayName="Sphere"
            :options="
              spherePowerList.map((x) => ({
                value: x.value,
                text: x.value,
              }))
            "
            v-model="prescriptionData.postData.sphereOS"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
        <div class="drop-down">
          <label>Cylinder</label>
          <select-input
            displayName="Cylinder"
            :options="
              cylindricalPowerList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.cylinderOS"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
        <div class="drop-down">
          <label>Axis</label>
          <select-input
            displayName="Axis"
            :options="
              axisPowerList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.axisOS"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
        <div class="drop-down">
          <label>Add Power</label>
          <select-input
            displayName="Add Power"
            :options="
              additionalPowerList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.powerOS"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
        <div class="drop-down">
          <label>Distances</label>
          <select-input
            displayName="Distances"
            :options="
              distancesList.map((x) => ({
                value: x.value,
                text: x.displayValue,
              }))
            "
            v-model="prescriptionData.postData.distanceOS"
            :disabled="isEvaluated.isReadOnly"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          Patient:
          <span class="text-highlight"> {{ patientinfo.value.name }}</span>
        </div>
        <!-- <md-button
          class="md-primary"
          @click="handleSavePrescription(prescriptionData)"
          v-show="!isEvaluated.isReadOnly"
          >Save RX</md-button
        > -->
      </div>
    </div>
  </div>
</template>
<script>
import SelectInput from "../../controls/SelectInput.vue";
import { mapState } from "vuex";
export default {
  inject: [
    "prescriptionData",
    "handleSavePrescription",
    "patientinfo",
    "findingsMaster",
    "isEvaluated",
    "navigationBlocker",
  ],
  name: "Prescription",
  components: {
    SelectInput,
  },
  computed: {
    ...mapState({
      distancesList: (state) => state.master.distancesList,
      additionalPowerList: (state) => state.master.additionalPowerList,
      axisPowerList: (state) => state.master.axisPowerList,
      cylindricalPowerList: (state) => state.master.cylindricalPowerList,
      spherePowerList: (state) => state.master.spherePowerList,
    }),
  },
};
</script>
