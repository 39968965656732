<template>
  <div></div>
  <!-- <div class="md-layout md-gutter">
    <div
      class="md-layout-item md-size-100"
      v-for="option in additionalFindings.list"
      :key="'group-' + option.groupID"
    >
      <div
        v-for="findings in option.findings"
        :key="'additional-findings-' + findings.findingsID"
      >
        <div
          v-if="selectedAdditionalFindings[field].includes(findings.findingsID)"
        >
          <b>{{ findings.findingsName }}</b>
          - recommendation :
          {{ findings.recommendation }}, Diagnosys :
          {{ findings.diagnosys }}
        </div>
      </div>
    </div>
    <div
      class="md-layout-item md-size-100"
      v-for="findings in commonFindings.list"
      :key="'common-findings-' + findings.findingsID"
    >
      <div v-if="selectedCommonFindings[field].includes(findings.findingsID)">
        <b>{{ findings.findingsName }}</b>
        - recommendation :
        {{ findings.recommendation }}, Diagnosys :
        {{ findings.diagnosys }}
      </div>
    </div>
  </div> -->
</template>
<script>
export default {
  props: ["field"],
  inject: [
    "additionalFindings",
    "commonFindings",
    "selectedAdditionalFindings",
    "selectedCommonFindings",
  ],
};
</script>
