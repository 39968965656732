<template>
  <findingsHandler
    :masterName="masterName"
    :page="page"
    v-model="findings.selectedFindings"
    :screeningId="manageScreening.screeningId"
  >
    <div>
      <div class="img-thumb scroll" v-if="lensImages.length > 1">
        <div class="img-thumb-inner">
          <div
            v-for="image in lensImages"
            :key="image.id"
            style="margin-right: 18px"
          >
            <drag
              :transfer-data="image"
              @dragstart="handleImageDrag"
              @dragend="handleImageDragEnd()"
              class="drop"
            >
              <img
                :src="image.imgLink.href"
                @error="handleImageError"
                name="paceholder"
                style="width: 100px; height: 100px"
              />
            </drag>
          </div>
        </div>
      </div>
      <div class="photo-container">
        <drop class="drop" @drop="handleImageDrop" v-if="lensImage">
          <img
            :src="lensImage.imgLink.href"
            name="paceholder"
            @error="handleImageError"
            @click="handleLensImageClick(lensImage.imgLink.href)"
          />
        </drop>

        <div v-else>
          <img src="@/assets/img/imageNA.jpg" name="paceholder" />
        </div>
        <md-dialog
          :md-active.sync="show"
          @md-clicked-outside="handleCloseView()"
        >
          <span @click="handleCloseView()" class="close-button topright"
            >&times;</span
          >
          <div class="dialog-size" @click="handleCloseView()">
            <div class="modal-content lensImageContainer">
              <!-- <div @click="$emit('onClose')"> -->
              <!-- <p>image displayed</p> -->
              <img
                style="width: 70%"
                :src="image"
                width="100%"
                @error="handleImageError"
              />
              <!-- </div> -->
              <!-- <div @click="$emit('onClose')" style="text-align: center"> -->
              <!-- <p>image displayed</p> -->

              <img
                style="width: 30%"
                src="@/assets/img/LOCS.png"
                width="100%"
                @error="handleImageError"
              />
              <!-- </div> -->
            </div>
          </div>
        </md-dialog>
        <!-- Headings & HR-->
        <!-- <findingsHandler
      :masterName="masterName"
      :page="page"
      v-model="findings.selectedFindings"
    > -->

        <!-- <lensFindings />
    </findingsHandler> -->
      </div>
      <div style="padding-top: 10px">
        <div class="label scroll">
          <div style="color: black">
            {{ this.getLableFindings(this.masterName, "right", "OD") }}
          </div>
          <div style="color: black">
            {{ this.getLableFindings(this.masterName, "left", "OS") }}
          </div>
        </div>
      </div>
    </div>
  </findingsHandler>
</template>
<script>
import { Drop, Drag } from "vue-drag-drop";

// import lensFindings from "@/components/Globechek/screeningeval/findings/lens-findings.vue";
import findingsHandler from "@/handlers/module-handlers/findings/findingsHandler.js";

export default {
  data() {
    return {
      masterName: "LensStatus",
      page: "screeningeval",
      formattedFindings: "",
      image: "",
      show: false,
      draglensImage: null,
    };
  },
  computed: {
    lensImages: function () {
      if (this.lensStatusPhotos.value && this.lensStatusPhotos.value.length > 0)
        return this.lensStatusPhotos.value.filter((x) =>
          this.isScreeningImage(x)
        );
      return [];
    },
    lensImage: function () {
      if (
        this.lensStatus.value &&
        this.lensStatus.value.length > 0 &&
        this.isScreeningImage(this.lensStatus.value[0])
      )
        return this.lensStatus.value[0];
      else if (this.lensImages.length > 0) return this.lensImages[0];
      return null;
    },
  },
  components: {
    drag: Drag,
    drop: Drop,
    findingsHandler,
  },
  inject: [
    "lensStatus",
    "lensStatusPhotos",
    "handleDrop",
    "findings",
    "getLableFindings",
    "handleImageClick",
    "handleImageError",
    "manageScreening",
    "imageView",
    "handleCloseImageView",
    "handleChildDragstart",
    "screeningEvalData",
    "isScreeningImage",
  ],
  name: "externalinfo",

  methods: {
    handleLensImageClick(image) {
      this.handleImageClick(image, this.masterName);
      this.show = true;
      this.image = image;
    },
    handleCloseView() {
      this.show = false;
    },
    handleImageDrag(image) {
      this.draglensImage = image;
    },
    handleImageDrop() {
      if (this.draglensImage) {
        this.lensStatus.value = [this.draglensImage];
        let img = this.screeningEvalData.selectedImages.filter(
          (i) =>
            i.screeningType == this.draglensImage.screeningType &&
            i.eyeSection.toLowerCase() ==
              this.draglensImage.eyeSection.toLowerCase()
        );
        if (img.length > 0) {
          let ind = this.screeningEvalData.selectedImages.findIndex(
            (i) =>
              i.screeningType == img[0].screeningType &&
              i.eyeSection.toLowerCase() == img[0].eyeSection.toLowerCase()
          );
          this.screeningEvalData.selectedImages.splice(ind, 1);
        }
        this.screeningEvalData.selectedImages.push(this.draglensImage);
      }
    },
    handleImageDragEnd() {
      this.draglensImage = null;
    },
  },
  // watch: {
  //   "findings.selectedFindings": function () {
  //     this.formattedFinding = this.getLableFindings(this.masterName);
  //   },
  // },
};
</script>

<style scoped>
.lensImageContainer {
  width: 100%;
}
.lensImageContainer img {
  width: 50%;
  display: inline-block;
}
.dialog-size {
  width: 1200px;
  overflow: auto;
}
.close-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.topright {
  position: absolute;
  right: 0;
  font-size: 30px;
  /* background: black; */
  /* opacity: 0.5; */
  padding-right: 8px;
  padding-top: 0px;
  top: 0;
}
</style>
