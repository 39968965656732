var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout-item md-size-100"},[_c('globe-activity-slim-card',{attrs:{"hasHeader":_vm.hasHeader},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('list-card-header',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("card-title")]},proxy:true},{key:"actions",fn:function(){return [_vm._t("card-actions")]},proxy:true}],null,true)})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item",attrs:{"align":"Left"}},[_c('list-header-layout',{attrs:{"isReadOnly":_vm.isReadonly},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("header-actions")]},proxy:true}],null,true)}),(
              _vm.list.pages[_vm.listInput.page] &&
              _vm.list.pages[_vm.listInput.page].isLoading
            )?_c('div',{staticClass:"globe-activity md-layout-item md-size-100 text-center"},[_c('spinner',{attrs:{"diameter":30}})],1):(
              _vm.list.pages[_vm.listInput.page] &&
              _vm.list.pages[_vm.listInput.page].items &&
              _vm.list.pages[_vm.listInput.page].items.length == 0
            )?_c('div',[_vm._v(" No Results Available ")]):_c('div',[_vm._t("content"),_c('list-footer-layout')],2)],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }