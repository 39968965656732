<template>
  <div>
    <fragment>
      <label class="title-label">Common Findings</label>
      <div class="checkbox-list-no-border scroll">
        <check-box-input
          :options="
            commonFindings.list
              .filter(
                (x) => x.eyeSection == 'Both' || x.eyeSection == eyeSection
              )
              .map((x) => ({
                value: x.findingsID,
                text: x.findingsName,
              }))
          "
          @onChange="handleFindingsChange"
          v-model="selectedCommonFindings[field]"
          :isDisabled="isReadOnly"
        >
        </check-box-input>
      </div>
    </fragment>
  </div>
</template>

//
<script>
export default {
  props: ["field", "isReadOnly"],
  data() {
    return {
      eyeSection: this.field == "right" ? "OD" : "OS",
    };
  },

  inject: [
    "commonFindings",
    "selectedCommonFindings",
    "handleCommonFindingsChange",
  ],
  methods: {
    handleFindingsChange(option) {
      this.handleCommonFindingsChange(option, this.field);
    },
  },
};
</script>
