<template>
  <div v-if="listDetails.count > 0">
    <div class="page-nave d-flex justify-content-center align-item-center">
      <div
        class="
          d-flex
          justify-content-center
          align-item-center
          small-size-column
        "
      >
        <pagination
          v-model="listInput.page"
          :perPage="listInput.size"
          :total="listDetails.count"
          class="pagination-no-border pagination-success mr-5"
        />
        <p class="m-2">
          Showing <b>{{ listDetails.count }}</b>
        </p>
        <P class="m-2">Per page:</P>
        <div class="page-nav-dropdown">
          <md-field class="my-1">
            <md-select v-model="listInput.size" name="pages">
              <md-option
                v-for="item in listDetails.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
                >{{ item }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../Pagination.vue";
export default {
  components: {
    Pagination,
  },
  inject: ["listDetails", "listInput"],
};
</script>
