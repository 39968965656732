<template>
  <div class="steper-content">
    <div id="diagnoses">
      <h4 class="title">Diagnosis</h4>
      <md-field>
        <md-textarea
          :readonly="isEvaluated.isReadOnly"
          v-model="diagnosisComments.value"
        ></md-textarea>
      </md-field>
      <h4 class="title">Recommendation</h4>
      <md-field>
        <md-textarea
          :readonly="isEvaluated.isReadOnly"
          v-model="recommendationComments.value"
        ></md-textarea>
      </md-field>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["isEvaluated", "diagnosisComments", "recommendationComments"],
};
</script>

<style></style>
