<template>
  <div v-if="hasHeader">
    <md-card class="md-card-global-sales">
      <md-card-header :class="getClass(headerColor)">
        <slot name="header" />
      </md-card-header>
      <md-card-content>
        <slot name="content" />
      </md-card-content>
    </md-card>
  </div>
  <div v-else>
    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: "GlobeActivitySlimCard",
  inject: ["featureDetails"],
  props: {
    headerColor: {
      type: String,
      default: "",
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>
