<template>
  <div>
    <div class="img-thumb scroll" v-if="ExternalImages.length > 1">
      <div class="img-thumb-inner">
        <div v-for="image in ExternalImages" :key="image.id">
          <drag
            :transfer-data="image"
            @dragstart="handleExternalImageDrag"
            @dragend="handleImageDragEnd()"
            class="drop"
          >
            <img
              :src="image.imgLink.href"
              @error="handleImageError"
              name="paceholder"
            />
          </drag>
        </div>
      </div>
    </div>
    <div>
      <!-- Headings & HR-->
      <!-- <findingsHandler
        :masterName="masterName"
        :page="page"
        v-model="findings.selectedFindings"
      > -->
      <div class="photo-container">
        <drop
          class="drop"
          @drop="handleExternalImageDrop()"
          v-if="ExternalImage"
        >
          <img
            :src="ExternalImage.imgLink.href"
            name="paceholder"
            @error="handleImageError"
            @click="handleImageClick(ExternalImage.imgLink.href, masterName)"
          />
        </drop>

        <div v-else>
          <img src="@/assets/img/imageNA.jpg" name="paceholder" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Drop, Drag } from "vue-drag-drop";

// import externalFindings from "@/components/Globechek/screeningeval/findings/external-findings";
// import findingsHandler from "@/handlers/module-handlers/findings/findingsHandler.js";

export default {
  data() {
    return {
      masterName: "ExternalPhoto",
      page: "screeningeval",
      draggedExternalImage: null,
    };
  },
  computed: {
    ExternalImages: function () {
      if (this.externalphotos.value && this.externalphotos.value.length > 0)
        return this.externalphotos.value.filter((x) =>
          this.isScreeningImage(x)
        );
      return [];
    },
    ExternalImage: function () {
      if (
        this.externalphoto.value &&
        this.externalphoto.value.length > 0 &&
        this.isScreeningImage(this.externalphoto.value[0])
      )
        return this.externalphoto.value[0];
      else if (this.ExternalImages.length > 0) return this.ExternalImages[0];
      return null;
    },
  },
  components: {
    drop: Drop,
    drag: Drag,
    // externalFindings,
    // findingsHandler,
  },
  inject: [
    "externalphoto",
    "handleDrop",
    "externalphotos",
    "findings",
    "getLableFindings",
    "handleImageClick",
    "handleImageError",
    "screeningEvalData",
    "isScreeningImage",
  ],
  name: "externalinfo",
  watch: {},
  methods: {
    handleExternalImageDrag(image) {
      this.draggedExternalImage = image;
    },
    handleExternalImageDrop() {
      if (this.draggedExternalImage) {
        this.externalphoto.value = [this.draggedExternalImage];
        let img = this.screeningEvalData.selectedImages.filter(
          (i) =>
            i.screeningType == this.draggedExternalImage.screeningType &&
            i.eyeSection.toLowerCase() ==
              this.draggedExternalImage.eyeSection.toLowerCase()
        );
        if (img.length > 0) {
          let ind = this.screeningEvalData.selectedImages.findIndex(
            (i) =>
              i.screeningType == img[0].screeningType &&
              i.eyeSection.toLowerCase() == img[0].eyeSection.toLowerCase()
          );
          this.screeningEvalData.selectedImages.splice(ind, 1);
        }
        this.screeningEvalData.selectedImages.push(this.draggedExternalImage);
      }
    },
    handleImageDragEnd() {
      this.draggedExternalImage = null;
    },
  },
};
</script>
