<template>
  <div class="modal-size-medium">
    <findings-form></findings-form>
  </div>
</template>
<script>
import FindingsForm from "./FindingsForm.vue";
export default {
  components: { FindingsForm },
};
</script>
