<template>
  <div>
    <findingsHandler
      :masterName="masterName"
      :page="page"
      v-model="findings.selectedFindings"
      :savedFindings="findings.savedFindings"
      :screeningId="manageScreening.screeningId"
    >
      <externalphotofindings />
    </findingsHandler>
  </div>
</template>

<script>
import externalphotofindings from "@/components/Globechek/screeningeval/findings/externalphotofindings";
import findingsHandler from "@/handlers/module-handlers/findings/findingsHandler.js";

export default {
  data() {
    return {
      masterName: "ExternalPhoto",
      page: "screeningeval",
    };
  },
  inject: ["findings", "manageScreening"],
  components: {
    externalphotofindings,
    findingsHandler,
  },
};
</script>
